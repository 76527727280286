import { FC } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import { capitalize } from "lodash";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  label: string;
  text?: string;
};

export const DeleteConfirmationModal: FC<Readonly<Props>> = ({ isOpen, onClose, label, onDelete, text }) => {
  const { toast } = useToast();

  const handleDelete = async () => {
    await onDelete();

    toast({
      id: "single-delete-toast",
      title: `${capitalize(label)} deleted successfully!`,
      variant: "success",
    });
  };

  return (
    <ConfirmationDialog
      confirmButtonText={`Delete ${label}`}
      isOpen={isOpen}
      title={`Delete ${label}`}
      variant="danger"
      onClose={onClose}
      onConfirm={handleDelete}
    >
      {text ? (
        <Paragraph>{text}</Paragraph>
      ) : (
        <Paragraph>Are you sure you want to delete this {label}? You won't be able to undo this.</Paragraph>
      )}
    </ConfirmationDialog>
  );
};
