import { FC, ReactElement } from "react";

import { Grid, Box, Text } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useGoogleAdsAccountsQuery } from "src/graphql";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Spinner } from "src/ui/loading";
import { Section } from "src/ui/section";

import { ModeField } from "../../mode-field";
import * as callConversions from "./call-conversions";
import * as clickConversionAdjustments from "./click-conversion-adjustments";
import * as clickConversions from "./click-conversions";
import * as customerMatchUserList from "./customer-match-user-list";
import * as offlineStoreConversions from "./offline-store-conversions";

const MODES = [
  { label: "Customer Match User List", value: "CustomerMatchUserList" },
  { label: "Click Conversions", value: "ClickConversions" },
  { label: "Click Conversion Adjustments", value: "ClickConversionAdjustments" },
  { label: "Call Conversions", value: "CallConversions" },
  { label: "Offline Store Conversions", value: "OfflineStoreConversions" },
];

export const GoogleAdsForm: FC = () => {
  const { errors, config, setConfig, model, destination } = useDestinationForm();

  const {
    data: accountsData,
    error: accountsError,
    isFetching: loadingAccounts,
    refetch: getAccounts,
  } = useGoogleAdsAccountsQuery({
    destinationId: String(destination?.id),
  });

  const accounts = accountsData?.googleGetAccounts;

  const renderForm = (mode: string) => {
    switch (mode) {
      case "ClickConversions":
        return <clickConversions.ClickConversionsForm config={config} destinationId={destination?.id} setConfig={setConfig} />;
      case "ClickConversionAdjustments":
        return (
          <clickConversionAdjustments.ClickConversionAdjustmentsForm
            config={config}
            destinationId={destination?.id}
            setConfig={setConfig}
          />
        );
      case "CallConversions":
        return <callConversions.CallConversionsForm config={config} destinationId={destination?.id} setConfig={setConfig} />;
      case "OfflineStoreConversions":
        return (
          <offlineStoreConversions.OfflineStoreConversionsForm
            config={config}
            destinationId={destination?.id}
            errors={errors}
            setConfig={setConfig}
          />
        );
      default:
        return (
          <customerMatchUserList.CustomerMatchUserListForm
            config={config}
            errors={errors}
            model={model}
            setConfig={setConfig}
          />
        );
    }
  };

  return (
    <>
      <AccountSelector
        accountId={config?.accountId}
        accounts={accounts}
        accountsError={accountsError}
        errors={errors}
        loading={loadingAccounts}
        loginAccountId={config?.loginAccountId}
        reload={getAccounts}
        setAccountIds={(accountId, loginAccountId) => {
          setConfig({ accountId, loginAccountId });
        }}
      />
      {config?.accountId && (
        <ModeField
          options={MODES}
          onChange={(mode) => {
            setConfig({ accountId: config?.accountId, loginAccountId: config?.loginAccountId, mode });
          }}
        />
      )}
      {config?.mode && renderForm(config.mode)}
    </>
  );
};

const Account = ({ account, rootId, accountId, setAccountIds, loginAccountId, isRoot = false }) => {
  const children: ReactElement[] = [];
  if (account?.child) {
    for (const childAccount of Object.values(account?.child) || []) {
      children.push(
        <Account
          account={childAccount}
          accountId={accountId}
          loginAccountId={loginAccountId}
          rootId={rootId}
          setAccountIds={setAccountIds}
        />,
      );
    }
  }

  if (account.disabled) {
    // You can not longer select a disabled account.
    return (
      <Box>
        <Text>{`${account?.name} (${account?.id})`}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Checkbox
        label={`${account?.name} (${account?.id})`}
        value={
          accountId?.replace(/[ -]/g, "") === account?.id &&
          (loginAccountId?.replace(/[ -]/g, "") === rootId || (!loginAccountId && isRoot))
        }
        onChange={() => {
          if (isRoot) {
            setAccountIds(account?.id, undefined);
          } else {
            setAccountIds(account?.id, rootId);
          }
        }}
      />
      {!!children?.length && (
        <Box sx={{ ml: 2, pl: 2, mt: 2, borderLeft: "medium", borderColor: "blacks.0" }}>
          <Grid gap={1}>{children}</Grid>
        </Box>
      )}
    </Box>
  );
};

const AccountSelector = ({ accounts, accountId, loginAccountId, setAccountIds, errors, accountsError, reload, loading }) => {
  const parsedAccounts = accounts ? JSON.parse(accounts) : {};

  return (
    <Section>
      <Field
        description="If an account is at the top level, it means you have direct access to that ad or manager account. Otherwise, Hightouch will access children accounts via the root account that you have direct access to."
        error={accountsError?.message || errors?.accountId || errors?.loginAccountId}
        label="Which account would you like to sync to?"
        loading={loading}
        reload={reload}
        size="large"
      >
        {loading ? (
          <Spinner size={64} />
        ) : (
          <Box sx={{ maxHeight: "400px", border: "small", overflow: "auto", p: 4 }}>
            <Grid gap={2}>
              {Object.values(parsedAccounts)?.map((account: any, i) => (
                <Account
                  key={i}
                  account={account}
                  accountId={accountId}
                  isRoot={true}
                  loginAccountId={loginAccountId}
                  rootId={account?.id}
                  setAccountIds={setAccountIds}
                />
              ))}
            </Grid>
          </Box>
        )}
      </Field>
    </Section>
  );
};

export default {
  form: GoogleAdsForm,
  validation: Yup.lazy<Record<string, unknown> | undefined>((config) => {
    switch (config?.["mode"]) {
      case "ClickConversions":
        return clickConversions.Schema;
      case "ClickConversionAdjustments":
        return clickConversionAdjustments.Schema;
      case "CallConversions":
        return callConversions.Schema;
      case "OfflineStoreConversions":
        return offlineStoreConversions.Schema;
      default:
        return customerMatchUserList.Schema;
    }
  }),
};
