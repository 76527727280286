import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { FromIdField } from "src/components/destinations/from-id-field";
import { IdMappingField } from "src/components/destinations/id-mapping-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { ObjectField } from "src/components/destinations/object-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useAsanaColumnsQuery, useAsanaWorkspacesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

export const validation = Yup.object().shape({
  object: Yup.string().required(),
  mode: Yup.string().when("object", {
    is: "projects",
    then: Yup.string().required().default("update"),
    otherwise: Yup.string().required().default("upsert"),
  }),
  workspaceId: Yup.string().required(),
  fromId: Yup.mixed().when("mode", {
    is: "update",
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required(),
  }),
  externalIdMapping: Yup.object().when("mode", {
    is: "update",
    then: COMMON_SCHEMAS?.externalIdMapping,
    otherwise: Yup.object().notRequired().default(undefined),
  }),
  mappings: COMMON_SCHEMAS?.mappings,
  customMappings: COMMON_SCHEMAS?.mappings,
});

const FIELDS = {
  tasks: [
    "approval_status",
    "assignee",
    "completed",
    "completed_by",
    "due_at",
    "due_on",
    "followers",
    "html_notes",
    "liked",
    "name",
    "notes",
    "parent",
    "projects",
    "resource_subtype",
    "start_on",
    "tags",
    "workspace",
  ],
  projects: [
    "archived",
    "color",
    "current_status",
    "default_view",
    "due_date",
    "due_on",
    "followers",
    "html_notes",
    "is_template",
    "name",
    "notes",
    "owner",
    "public",
    "start_on",
  ],
};

const OBJECTS = [
  { label: "Tasks", value: "tasks" },
  { label: "Projects", value: "projects" },
];

export const AsanaForm: FC = () => {
  const { config, setConfig, destination, errors } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useAsanaColumnsQuery(
    {
      destinationId: String(destination?.id),
      workspaceId: config?.workspaceId,
    },
    { enabled: Boolean(config?.workspaceId) },
  );

  const columns = columnsData?.asanaListCustomFields?.fields;

  const {
    data: workspacesData,
    error: workspacesError,
    isFetching: loadingWorkspaces,
    refetch: getWorkspaces,
  } = useAsanaWorkspacesQuery({
    destinationId: String(destination?.id),
  });

  const workspaces = workspacesData?.asanaListWorkspaces?.workspaces;

  const modeOptions =
    config?.object === "projects"
      ? [{ label: "Update", value: "update" }]
      : [
          { label: "Upsert", value: "upsert" },
          { label: "Update", value: "update" },
        ];

  const externalIdColumns =
    config?.object === "tasks"
      ? [
          { label: "External Task ID", value: "external_id" },
          { label: "Asana Task ID", value: "gid" },
        ]
      : [{ label: "Asana Object ID", value: "gid" }];

  const workspaceOptions = workspaces?.map((a) => ({ label: a.name, value: a.id }));

  const columnOptions = columns?.map((a) => ({ label: a?.name, value: a?.id })) || [];

  return (
    <>
      <ObjectField options={OBJECTS} />

      {config?.object && (
        <ModeField
          options={modeOptions}
          path={[config.object]}
          onChange={(mode) => {
            setConfig({ mode, workspaceId: config?.workspaceId, object: config?.object });
          }}
        />
      )}

      {config.object && config.mode && (
        <Section>
          <Grid gap={8}>
            {config?.mode === "update" && <IdMappingField options={externalIdColumns} />}

            {config?.mode === "upsert" && <FromIdField fieldName="External ID" />}

            <Field
              error={errors?.workspaceId || workspacesError?.message}
              label={`Which workspace are your ${config?.object} located in?`}
            >
              <Select
                isError={errors?.workspaceId}
                isLoading={loadingWorkspaces}
                options={workspaceOptions}
                placeholder="Select an Asana workspace..."
                reload={getWorkspaces}
                value={config?.workspaceId ? workspaceOptions?.find((s) => config?.workspaceId === s.value) : null}
                width="340px"
                onChange={(selected) => setConfig({ ...config, workspaceId: selected?.value })}
              />
            </Field>
          </Grid>
        </Section>
      )}

      {config.object && config.mode && config.workspaceId && (
        <>
          <Section>
            <MappingsField options={FIELDS[config?.object]?.map((f) => ({ label: f, value: f })) || []} />
          </Section>
          <Section>
            <MappingsField
              isCustom
              error={columnsError?.message}
              loading={loadingColumns}
              options={columnOptions}
              reload={getColumns}
            />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  validation,
  form: AsanaForm,
};
