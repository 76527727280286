import { FC, useState } from "react";

import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";

import { Row } from "src/ui/box";

import { SidebarForm } from "../../components/page";
import { Permission } from "../../components/permission";
import { useUpdateSourceV2Mutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { Field } from "../../ui/field";
import { Heading } from "../../ui/heading";
import { Link } from "../../ui/link";
import { UseSourceResult } from "../../utils/sources";

export type WarehouseSyncLogsConfig = {
  changelog?: {
    audience: boolean;
  };
  snapshot?: {
    audience: boolean;
  };
  syncRuns?: {
    audience: boolean;
  };
};

type Props = {
  source: NonNullable<UseSourceResult["data"]>;
};

export const SyncLog: FC<Readonly<Props>> = ({ source }) => {
  const { addToast } = useToasts();
  const [updatingWarehouseSyncLogsConfig, setUpdatingWarehouseSyncLogsConfig] = useState<boolean>(false);
  const [changelogEnabled, setChangelogEnabled] = useState<boolean>(source?.warehouse_history_config?.changelog ? true : false);
  const [snapshotEnabled, setSnapshotEnabled] = useState<boolean>(source?.warehouse_history_config?.snapshot ? true : false);
  const [syncRunEnabled, setSyncRunEnabled] = useState<boolean>(source?.warehouse_history_config?.syncRuns ? true : false);
  const [changelogAudienceEnabled, setChangelogAudienceEnabled] = useState<boolean>(
    source?.warehouse_history_config?.changelog?.audience ? true : false,
  );
  const [snapshotAudienceEnabled, setSnapshotAudienceEnabled] = useState<boolean>(
    source?.warehouse_history_config?.snapshot?.audience ? true : false,
  );
  const [syncRunAudienceEnabled, setSyncRunAudienceEnabled] = useState<boolean>(
    source?.warehouse_history_config?.syncRuns?.audience ? true : false,
  );

  const { mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const updateWarehouseSyncLogsConfig = async () => {
    setUpdatingWarehouseSyncLogsConfig(true);
    const warehouseSyncLogsConfig: WarehouseSyncLogsConfig = {};
    if (changelogEnabled) {
      warehouseSyncLogsConfig.changelog = {
        audience: changelogAudienceEnabled,
      };
    }
    if (snapshotEnabled) {
      warehouseSyncLogsConfig.snapshot = {
        audience: snapshotAudienceEnabled,
      };
    }
    if (syncRunEnabled) {
      warehouseSyncLogsConfig.syncRuns = {
        audience: syncRunAudienceEnabled,
      };
    }
    await updateSource({
      id: String(source.id),
      object: {
        warehouse_history_config: warehouseSyncLogsConfig,
      },
      append: {},
    });

    setUpdatingWarehouseSyncLogsConfig(false);
    addToast(`Sync configuration updated!`, {
      appearance: "success",
    });
  };

  return (
    <Row sx={{ alignItems: "flex-start" }}>
      <Grid gap={6} mr={8}>
        <Heading>Warehouse sync logs</Heading>
        <Text>
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs`}>Warehouse sync logs</Link> writes information
          on the status of each row of your sync back into your warehouse. This is useful for analyzing sync failures and
          changes in your data over time.
          <br />
          <br />
          Specific use cases include{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
            analyzing common syncs errors
          </Link>
          ,{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}>
            visualizing when users enter and exit audiences
          </Link>
          , and{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}>
            tracking the status of rows across syncs
          </Link>
          .
        </Text>
        <Field
          description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
          label="Tables"
          size="large"
        >
          <Grid gap={3}>
            <Checkbox
              description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
              label="Snapshot"
              size="large"
              value={snapshotEnabled}
              onChange={(value: boolean) => {
                setSnapshotEnabled(value);
              }}
            />
            {snapshotEnabled && (
              <Row sx={{ ml: "4" }}>
                <Checkbox
                  description="Only enable this sync log table for audience syncs"
                  label="Audience syncs only"
                  size="large"
                  value={snapshotAudienceEnabled}
                  onChange={(value: boolean) => {
                    setSnapshotAudienceEnabled(value);
                  }}
                />
              </Row>
            )}
            <Checkbox
              description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
              label="Changelog"
              size="large"
              value={changelogEnabled}
              onChange={(value: boolean) => {
                setChangelogEnabled(value);
              }}
            />
            {changelogEnabled && (
              <Row sx={{ ml: "4" }}>
                <Checkbox
                  description="Only enable this sync log table for audience syncs"
                  label="Audience syncs only"
                  size="large"
                  value={changelogAudienceEnabled}
                  onChange={(value: boolean) => {
                    setChangelogAudienceEnabled(value);
                  }}
                />
              </Row>
            )}
            <Checkbox
              description="Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
              label="Sync runs"
              size="large"
              value={syncRunEnabled}
              onChange={(value: boolean) => {
                setSyncRunEnabled(value);
              }}
            />
            {syncRunEnabled && (
              <Row sx={{ ml: "4" }}>
                <Checkbox
                  description="Only enable this sync log table for audience syncs"
                  label="Audience syncs only"
                  size="large"
                  value={syncRunAudienceEnabled}
                  onChange={(value: boolean) => {
                    setSyncRunAudienceEnabled(value);
                  }}
                />
              </Row>
            )}
          </Grid>
        </Field>
      </Grid>

      <SidebarForm
        hideInviteTeammate
        hideSendMessage
        buttons={[
          <Permission key={0}>
            <Button
              loading={updatingWarehouseSyncLogsConfig}
              sx={{ width: "100%" }}
              onClick={() => updateWarehouseSyncLogsConfig()}
            >
              Save
            </Button>
          </Permission>,
        ]}
        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/`}
        invite="If you need help setting up this sync"
        name="warehouse sync logs"
      />
    </Row>
  );
};
