import { ReactElement } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useGoogleAdsConversionActionsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { ColumnOrConstantField } from "../../column-or-constant-field";
import { MappingsField } from "../../mappings-field";
import { PIIHashingField } from "../../pii-hashing-field";

const MAPPINGS = [
  // User identifiers
  { label: "Email", value: "email", type: StandardFieldType.STRING },
  { label: "Phone", value: "phone", type: StandardFieldType.STRING },
  { label: "First Name", value: "firstName", type: StandardFieldType.STRING },
  { label: "Last Name", value: "lastName", type: StandardFieldType.STRING },
  { label: "Country", value: "country", type: StandardFieldType.STRING },
  { label: "Zip", value: "zip", type: StandardFieldType.STRING },
  { label: "State", value: "state", type: StandardFieldType.STRING },
  { label: "City", value: "city", type: StandardFieldType.STRING },
  // Transaction attributes
  {
    label: "Transaction time",
    value: "time",
    type: StandardFieldType.DATETIME,
    required: true,
  },
  {
    label: "Transaction amount (micros)",
    value: "amount",
    type: StandardFieldType.NUMBER,
    required: true,
  },
  {
    label: "Transaction currency",
    value: "currency",
    type: StandardFieldType.STRING,
  },
  {
    label: "Order Id",
    value: "orderId",
    type: StandardFieldType.STRING,
  },
  {
    label: "Custom value",
    value: "customValue",
    type: StandardFieldType.STRING,
  },
  // Store attributes
  {
    label: "Store code",
    value: "storeCode",
    type: StandardFieldType.STRING,
  },
  // Item attributes
  {
    label: "Item Id",
    value: "itemId",
    type: StandardFieldType.STRING,
  },
  {
    label: "Item country code (CLDR)",
    value: "itemCountryCode",
    type: StandardFieldType.STRING,
  },
  {
    label: "Item language code (ISO 639-1)",
    value: "itemLanguageCode",
    type: StandardFieldType.STRING,
  },
  {
    label: "Item quantity",
    value: "itemQuantity",
    type: StandardFieldType.NUMBER,
  },
  {
    label: "Merchant Id",
    value: "itemMerchantId",
    type: StandardFieldType.NUMBER,
  },
];

const PHONE_COUNTRY_COLUMN_OPTION = { label: "Phone Country ISO Code", value: "phoneCountry", type: StandardFieldType.STRING };

export function OfflineStoreConversionsForm({
  destinationId,
  config,
  setConfig,
  errors,
}: Readonly<{
  destinationId: string;
  config: Record<string, unknown>;
  setConfig: (configuration: Record<string, unknown>) => void;
  errors?: Record<string, unknown>;
}>): ReactElement<any, any> {
  const columnOptions = [...MAPPINGS];
  if (config?.cleanPhone) {
    columnOptions.push(PHONE_COUNTRY_COLUMN_OPTION);
  }

  const {
    data: conversionActionsData,
    error: conversionActionsError,
    isFetching: loadingConversionActions,
    refetch: getConversionActions,
  } = useGoogleAdsConversionActionsQuery({
    destinationId: String(destinationId),
    mode: config?.mode as string,
    accountId: config?.accountId as string,
    loginAccountId: config?.loginAccountId as string,
  });

  const conversionActions = conversionActionsData?.googleListConversionActions?.actions;

  const conversionActionsOptions = conversionActions?.map((a) => ({
    label: a.name,
    value: a.resourceName,
  }));

  return (
    <>
      <Section>
        <ColumnOrConstantField
          columnLabel="Which column contains the conversion action name, resource name or ID?"
          constantDescription="This is the action to each conversion of this sync"
          constantInput={
            <Select
              isError={Boolean(conversionActionsError)}
              isLoading={loadingConversionActions}
              options={conversionActionsOptions}
              placeholder="Select a conversion action..."
              reload={getConversionActions}
              value={config.actionId ? conversionActionsOptions?.find((a) => config?.actionId === a.value) : null}
              width="240px"
              onChange={(action) => setConfig({ ...config, actionId: action.value })}
            />
          }
          constantLabel="What is the conversion action?"
          error={conversionActionsError?.message}
          fieldProps={{ size: "small" }}
          property="actionId"
        />
      </Section>
      <Section>
        <Grid gap={8}>
          <Field
            optional
            description="A custom key string to segment store sales conversions. If set, please select a mapping for the custom value below."
            label="Custom Key"
          >
            <Input
              defaultValue={config?.customKey as string}
              placeholder="Enter a custom key..."
              sx={{ width: "180px" }}
              onChange={(customKey) => setConfig({ ...config, customKey })}
            />
          </Field>

          <Field optional description="Defaults to 1.0" label="Loyalty Fraction">
            <Input
              defaultValue={config?.loyaltyFraction as string}
              max="1"
              min="0"
              placeholder="1"
              sx={{ width: "180px" }}
              type="number"
              onChange={(loyaltyFraction) =>
                setConfig({ ...config, loyaltyFraction: loyaltyFraction ? Number(loyaltyFraction) : undefined })
              }
            />
          </Field>
          <Field optional description="Defaults to 1.0" label="Transaction Upload Fraction">
            <Input
              defaultValue={config?.transactionUploadFraction as string}
              max="1"
              min="0"
              placeholder="1"
              sx={{ width: "180px" }}
              type="number"
              onChange={(transactionUploadFraction) =>
                setConfig({
                  ...config,
                  transactionUploadFraction: transactionUploadFraction ? Number(transactionUploadFraction) : undefined,
                })
              }
            />
          </Field>
          <Field label="Is this a third party data upload?">
            <RadioGroup
              options={[
                { label: "First party data", value: false },
                {
                  label: "Third party data",
                  value: true,
                },
              ]}
              value={config?.isThirdParty as boolean}
              onChange={(isThirdParty) => {
                setConfig({ ...config, isThirdParty });
              }}
            />
          </Field>
        </Grid>
      </Section>
      {config?.isThirdParty && (
        <Section>
          <Grid gap={8}>
            <Field optional label="Third Party Partner ID">
              <Input
                defaultValue={config?.partnerId as string}
                placeholder="Enter a partner ID..."
                sx={{ width: "180px" }}
                onChange={(partnerId) => setConfig({ ...config, partnerId })}
              />
            </Field>
            <Field label="Bridge Map Version ID">
              <Input
                defaultValue={config?.bridgeMapVersionId as string}
                placeholder="Enter a version ID..."
                sx={{ width: "180px" }}
                onChange={(bridgeMapVersionId) => setConfig({ ...config, bridgeMapVersionId })}
              />
            </Field>
            <Field optional description="Defaults to 1.0" label="Third Party Valid Transaction Fraction">
              <Input
                defaultValue={config?.validTransactionFraction as string}
                max="1"
                min="0"
                placeholder="1"
                sx={{ width: "180px" }}
                type="number"
                onChange={(validTransactionFraction) =>
                  setConfig({
                    ...config,
                    validTransactionFraction: validTransactionFraction ? Number(validTransactionFraction) : undefined,
                  })
                }
              />
            </Field>
            <Field optional description="Defaults to 1.0" label="Third Party Partner Match Fraction">
              <Input
                defaultValue={config?.partnerMatchFraction as string}
                max="1"
                min="0"
                placeholder="1"
                sx={{ width: "180px" }}
                type="number"
                onChange={(partnerMatchFraction) =>
                  setConfig({
                    ...config,
                    partnerMatchFraction: partnerMatchFraction ? Number(partnerMatchFraction) : undefined,
                  })
                }
              />
            </Field>
            <Field optional description="Defaults to 1.0" label="Third Party Partner Upload Fraction">
              <Input
                defaultValue={config?.partnerUploadFraction as string}
                max="1"
                min="0"
                placeholder="1"
                sx={{ width: "180px" }}
                type="number"
                onChange={(partnerUploadFraction) =>
                  setConfig({
                    ...config,
                    partnerUploadFraction: partnerUploadFraction ? Number(partnerUploadFraction) : undefined,
                  })
                }
              />
            </Field>
          </Grid>
        </Section>
      )}
      <Section>
        <MappingsField options={columnOptions} />
      </Section>
      <PIIHashingField />
      <Section>
        <Grid gap={8}>
          <Field
            description={
              "If enabled, Hightouch will try to convert values \
              mapped to phone data into E164 format. If the phone \
              number cannot be converted, it will not be included in \
              the request."
            }
            label="Would you like Hightouch to clean your phone numbers?"
            size="large"
          >
            <RadioGroup
              options={[
                { label: "Yes, clean phone numbers for me", value: true },
                { label: "No, I will clean them myself", value: undefined },
              ]}
              value={config?.cleanPhone as boolean}
              onChange={(cleanPhone) => {
                setConfig({ ...config, cleanPhone });
              }}
            />
          </Field>
          {config?.cleanPhone ? (
            <Field
              description={"Mapping a phone country column in the \
                field mappings will overwrite this value."}
              label="What is the default country for the phone numbers (ISO code)?"
            >
              <Input
                defaultValue={config?.defaultCountry as string}
                error={Boolean(errors?.defaultCountry)}
                placeholder="US"
                sx={{ width: "240px" }}
                onChange={(defaultCountry) => {
                  setConfig({
                    ...config,
                    defaultCountry,
                  });
                }}
              />
            </Field>
          ) : undefined}
        </Grid>
      </Section>
    </>
  );
}

export const Schema = Yup.object().shape(
  {
    accountId: Yup.string().required(),
    loginAccountId: Yup.string().nullable().notRequired(),
    mode: Yup.string().required().oneOf(["OfflineStoreConversions"]),
    mappings: COMMON_SCHEMAS.mappings,
    actionId: COMMON_SCHEMAS.columnOrConstant,
    loyaltyFraction: Yup.number().notRequired(),
    transactionUploadFraction: Yup.number().notRequired(),
    customKey: Yup.string().notRequired(),
    isThirdParty: Yup.boolean().notRequired(),
    validTransactionFraction: Yup.number().notRequired(),
    partnerMatchFraction: Yup.number().notRequired(),
    partnerUploadFraction: Yup.number().notRequired(),
    bridgeMapVersionId: Yup.string().notRequired(),
    partnerId: Yup.string().notRequired(),
    cleanPhone: Yup.boolean().notRequired(),
    defaultCountry: Yup.string().when("cleanPhone", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    disablePIIHashing: Yup.boolean().notRequired(),
  },
  [["cleanPhone", "defaultCountry"]],
);
