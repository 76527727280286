import { useEffect, useMemo, useState, FC } from "react";

import { InboundRun, OutboundRun } from "@hightouch/lib/git-sync/models";
import * as Sentry from "@sentry/browser";
import { orderBy } from "lodash";
import pluralize from "pluralize";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Outlet, Route, Routes, useLocation, useOutletContext } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";

import gitSyncImage from "src/components/extensions/assets/git-sync.png";
import { Overview } from "src/components/extensions/overview";
import { GitBranchSelector } from "src/components/git/git-branch-selector";
import { GitChecksToggle } from "src/components/git/git-checks-toggle";
import { GitCredentialsFields } from "src/components/git/git-credentials-fields";
import { GitRepositorySelector } from "src/components/git/git-repository-selector";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  GitCredentials,
  GitSyncConfigsQuery,
  ResourcePermissionGrant,
  useCreateGitSyncConfigsMutation,
  useGitCredentialsQuery,
  useGitSyncConfigsQuery,
  useUpdateGitSyncConfigsMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Badge } from "src/ui/badge";
import { Column, Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { BitbucketIcon, GitHubIcon, GitIcon, GitlabIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { Toggle } from "src/ui/toggle";
import { useNavigate } from "src/utils/navigate";
import { formatDatetime } from "src/utils/time";

// eslint-disable-next-line import/no-absolute-path

import { SyncStatusBadge } from "../../components/extensions/sync-status-badge";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
  Runs = "Runs",
}

const TABS = [Tab.Overview, Tab.Configuration, Tab.Runs];

export const GitSync: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Hightouch can write your sync and model configurations to YAML files in a GitHub, Bitbucket, or GitLab repository. This integration is bidirectional, so you can also edit existing resources or create new ones via Git. Use this integration for version control, rolling back changes, or building your own approval flow based on pull/merge requests."
              icon={GitIcon}
              image={gitSyncImage}
              integrations={[
                { name: "GitHub", icon: GitHubIcon },
                { name: "Bitbucket", icon: BitbucketIcon },
                { name: "GitLab", icon: GitlabIcon },
              ]}
              subtitle="Manage syncs and models in a Git repo"
              title="Version control with Git"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
        <Route element={<Runs />} path="runs" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : path === "runs" ? Tab.Runs : Tab.Overview;

  const { data: config, isLoading: configLoading } = useGitSyncConfigsQuery(
    {},
    { select: (data) => data.git_sync_configs?.[0] },
  );

  const { data: credentials, isLoading: credsLoading } = useGitCredentialsQuery(undefined, {
    select: (data) => data.git_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Git sync" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Configuration) {
            navigate("configuration");
          } else if (tab === Tab.Runs) {
            navigate("runs");
          } else {
            navigate("/extensions/git-sync");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ config, credentials, loading: configLoading || credsLoading }} />
    </Page>
  );
};

interface OutletContext {
  config: GitSyncConfigsQuery["git_sync_configs"][0];
  credentials: GitCredentials;
  loading: boolean;
}

const Configuration: FC = () => {
  const { config, credentials, loading } = useOutletContext<OutletContext>();
  const { featureFlags, workspace } = useUser();
  const { addToast } = useToasts();
  const formMethods = useForm();

  const { mutateAsync: create } = useCreateGitSyncConfigsMutation();
  const { mutateAsync: update } = useUpdateGitSyncConfigsMutation();

  const unidirectionalEnabled = Boolean(featureFlags?.unidirection_git_sync);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  const fullResync = async () => {
    try {
      await update({
        id: String(config.id),
        object: {
          full_resync: true,
        },
      });

      addToast("Resync will begin shortly.", {
        appearance: "success",
      });
    } catch (e) {
      addToast("There was an error resyncing", { appearance: "error" });
      Sentry.captureException(e);
    }
  };

  const submit = async (data) => {
    try {
      if (!config?.id) {
        await create({
          object: {
            ...data,
            git_credential_id: credentials?.id,
          },
        });
      } else {
        await update({
          id: String(config.id),
          object: data,
        });
      }
      if (!config?.enabled && data.enabled) {
        analytics.track("Git Sync Enabled", {
          workspace_id: workspace?.id,
        });
      }

      if (config?.enabled && !data.enabled) {
        analytics.track("Git Sync Disabled", {
          workspace_id: workspace?.id,
        });
      }

      addToast("Configuration saved!", {
        appearance: "success",
      });
    } catch (e) {
      addToast("There was an error saving your configuration.", {
        appearance: "error",
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      enabled: config?.enabled ?? false,
      checks_enabled: config?.checks_enabled ?? false,
      repository: config?.repository ?? "",
      branch: config?.branch ?? "",
      path: config?.path ?? "",
    });
  }, [config]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <FormProvider {...formMethods}>
      <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
        <Row sx={{ justifyContent: "space-between" }}>
          <Container center={false} size="small">
            <Grid gap={12}>
              <Column>
                <Heading sx={{ mb: 6 }}>Status</Heading>
                <Grid gap={4}>
                  <Card size="small">
                    <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                      <Row sx={{ alignItems: "center" }}>
                        <GitIcon />
                        <Text sx={{ ml: 2 }}>Git Sync</Text>
                      </Row>
                      <Controller
                        name="enabled"
                        render={({ field }) => (
                          <Toggle label={field.value ? "Enabled" : "Disabled"} value={field.value} onChange={field.onChange} />
                        )}
                      />
                    </Row>
                  </Card>

                  <Card size="small">
                    {!unidirectionalEnabled && (
                      <Column sx={{ mb: 4 }}>
                        <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                          <Text>Hightouch to Git</Text>
                          <SyncStatusBadge
                            error={config?.outbound_error}
                            lastAttemptedAt={config?.last_attempted_at}
                            setup={Boolean(config)}
                          />
                        </Row>
                        <ErrorBlock error={config?.outbound_error} />
                      </Column>
                    )}
                    <Column>
                      <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Text>Git to Hightouch</Text>
                        <SyncStatusBadge
                          error={config?.inbound_error}
                          lastAttemptedAt={!config?.outbound_error ? config?.last_attempted_at : ""}
                          setup={Boolean(config)}
                        />
                      </Row>
                      <ErrorBlock error={config?.inbound_error} />
                    </Column>
                  </Card>
                  {formMethods.getValues("enabled") && (
                    <Card size="small">
                      <GitChecksToggle
                        credentials={credentials}
                        description="Check your Hightouch Git Sync config files for breaking changes before they are merged."
                      />
                    </Card>
                  )}
                </Grid>
              </Column>

              <Column>
                <Heading sx={{ mb: 6 }}>Configuration</Heading>
                <Grid gap={8}>
                  <GitCredentialsFields
                    credentials={credentials}
                    isSetup={Boolean(config?.git_credential?.id)}
                    page="git-sync"
                  />
                  {!credentials || (credentials?.type === "github_app" && !credentials?.credentials) ? null : (
                    <>
                      <GitRepositorySelector credentials={credentials} />
                      <GitBranchSelector credentials={credentials} />
                      <Field optional description="Specify a custom path to look for the sync and model folders." label="Path">
                        <Input placeholder="./hightouch" {...register("path")} />
                      </Field>
                    </>
                  )}
                </Grid>
              </Column>
            </Grid>
          </Container>
          <SidebarForm
            buttons={
              <Permission>
                <Button disabled={!isDirty} loading={isSubmitting} sx={{ width: "100%" }} onClick={handleSubmit(submit)}>
                  Save
                </Button>
                <Button sx={{ width: "100%" }} variant="secondary" onClick={fullResync}>
                  Full Resync
                </Button>
              </Permission>
            }
            docsUrl="syncs/git-sync"
            name="Git sync"
          />
        </Row>
      </PermissionProvider>
    </FormProvider>
  );
};

export const Runs: FC = () => {
  const { config } = useOutletContext<OutletContext>();

  const [inboundChanges, setInboundChanges] = useState<InboundRun["affected_resources"] | null>();
  const [outboundChanges, setOutboundChanges] = useState<OutboundRun["commits"] | null>();

  const outboundRuns =
    config?.git_outbound_runs?.map((o) => ({
      createdAt: o.created_at,
      type: "outbound",
      state: o.changelog_id,
      numChanges: o.commits?.length || 0,
      changes: o.commits,
    })) || [];
  const inboundRuns =
    config?.git_inbound_runs?.map((i) => ({
      createdAt: i.created_at,
      type: "inbound",
      state: i.commit,
      numChanges: i.affected_resources.syncs.length + i.affected_resources.models.length || 0,
      changes: i.affected_resources,
    })) || [];

  const rows = orderBy([...outboundRuns, ...inboundRuns], ["createdAt"], ["desc"]);

  const getCommitUrl = (commit: string) => {
    if (config?.repository) {
      const repo = config?.repository.toString();
      const url = repo.endsWith(".git") ? repo.slice(0, -4) : repo;
      return `${url}/commit/${commit}`;
    }
    return "";
  };

  const columns = useMemo(
    () => [
      {
        name: "Type",
        key: "type",
        cell: (type) =>
          type === "inbound" ? <Badge variant="green">Inbound</Badge> : <Badge variant="indigo">Outbound</Badge>,
      },
      {
        name: "Completed",
        key: "createdAt",
        cell: (createdAt) => formatDatetime(createdAt),
      },
      {
        name: "State",
        max: "200px",
        min: "150px",
        cell: ({ type, state }) =>
          type === "inbound" ? <Link newTab to={getCommitUrl(state)}>{`Commit: ${state}`}</Link> : `Changelog ID: ${state}`,
      },
      {
        name: "Changes",
        cell: ({ type, numChanges, changes }) => {
          if (numChanges) {
            return (
              <Link
                onClick={() => {
                  if (type === "inbound") {
                    setInboundChanges(changes);
                  } else {
                    setOutboundChanges(changes);
                  }
                }}
              >{`${numChanges} ${pluralize("resource", numChanges)} changed`}</Link>
            );
          }
          return "None";
        },
      },
    ],
    [],
  );

  return (
    <>
      <Row sx={{ mb: 6, alignItems: "center" }}>
        <Heading>Runs</Heading>
      </Row>

      <Table
        columns={columns}
        data={rows}
        placeholder={{
          title: "No runs",
          body: "Enable syncing to your repository",
          error: "Runs failed to load, please try again.",
        }}
      />

      <Modal
        info
        isOpen={Boolean(outboundChanges)}
        sx={{ maxWidth: "500px", width: "100%" }}
        title="Git commits"
        onClose={() => {
          setOutboundChanges(null);
        }}
      >
        {outboundChanges?.map((c, i) => (
          <Link key={i} newTab sx={{ display: "block" }} to={getCommitUrl(c)}>
            {getCommitUrl(c)}
          </Link>
        ))}
      </Modal>

      <Modal
        info
        isOpen={Boolean(inboundChanges)}
        sx={{ maxWidth: "500px", width: "100%" }}
        title="Changed resources"
        onClose={() => {
          setInboundChanges(null);
        }}
      >
        <Grid gap={6}>
          <Field label="Syncs">
            {inboundChanges?.syncs?.map((s) => (
              <Link key={s} newTab sx={{ display: "block" }} to={`/syncs/${s}`}>
                Sync {s}
              </Link>
            ))}
          </Field>
          <Field label="Models">
            {inboundChanges?.models?.map((m) => (
              <Link key={m} newTab sx={{ display: "block" }} to={`/models/${m}`}>
                Model {m}
              </Link>
            ))}
          </Field>
        </Grid>
      </Modal>
    </>
  );
};

const ErrorBlock = ({ error }: { error: any }) => {
  if (!error) return null;
  return (
    <>
      {error?.fatal && (
        <Message sx={{ width: "100%", maxWidth: "100%", my: 2 }} variant="error">
          Hightouch has detected a fatal error and temporarily disabled git sync. Examples of fatal errors are: your credentials
          may be invalid, you may not have access to this repository, etc.
        </Message>
      )}
      {error?.temp && (
        <Message sx={{ width: "100%", maxWidth: "100%", my: 2 }} variant="warning">
          Hightouch has detected a possible race condition and will attempt to automatically resolve. No action is required.
        </Message>
      )}
      <Text as="pre" sx={{ bg: "base.1", p: 4, wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
        {JSON.stringify(error, null, 2)}
      </Text>
    </>
  );
};
