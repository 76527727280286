import { FC, useEffect, useState } from "react";

import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, FormField, Spinner, TextInput, useToast, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Outlet, Route, Routes, useOutletContext, useLocation } from "react-router-dom";
import { Grid } from "theme-ui";

import dbtCloudImage from "src/components/extensions/assets/dbt-cloud.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { ScheduleType } from "src/components/schedule/types";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  useDbtCredentialsQuery,
  DbtCredentialsQuery,
  useCreateDbtCredentialMutation,
  ResourcePermissionGrant,
  useDeleteDbtCloudCredentialMutation,
  useTestDbtCloudCredentialQuery,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { DBTIcon } from "src/ui/icons";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

import { ConnectedExtension, DependentSyncsModal } from "./common";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const DbtCloud: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="When your Hightouch sync depends on a table or materialized view generated by dbt, you can schedule it to run after completion of the associated dbt job."
              icon={DBTIcon}
              image={dbtCloudImage}
              subtitle="Trigger syncs upon completion of dbt jobs"
              title="dbt Cloud"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const {
    data: credentials,
    isLoading: loading,
    isRefetching: refetching,
    refetch: refetchCredentials,
  } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "dbt Cloud" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/dbt-cloud");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading: loading || refetching, refetchCredentials }} />
    </Page>
  );
};

interface OutletContext {
  credentials: DbtCredentialsQuery["dbt_credentials"][0];
  loading: boolean;
  refetchCredentials: () => void;
}
type DbtCloudTestStatus = "failed" | "loading" | "success" | "error";

const Configuration: FC = () => {
  const [apiKey, setApiKey] = useState("");
  const [subdomain, setSubdomain] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);

  const { credentials, loading, refetchCredentials } = useOutletContext<OutletContext>();
  const { mutateAsync: deleteExtension, isLoading: extensionDeleting } = useDeleteDbtCloudCredentialMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [testStatus, setTestStatus] = useState<DbtCloudTestStatus>("loading");

  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestDbtCloudCredentialQuery({}, { select: (data) => data.testDbtCloudCredential, enabled: !!credentials });

  const { toast } = useToast();

  const { mutateAsync: create, isLoading: isCreatingExtension } = useCreateDbtCredentialMutation();

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult) {
        setTestStatus("failed");
        toast({
          id: "dbt-cloud-test",
          variant: "error",
          title: "dbt Cloud credentials test failed.",
          message: "Please check your API credentials on your dbt Cloud account.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const submit = async () => {
    try {
      if (credentials?.id) {
        // We don't allow updates right now
        return;
      } else {
        await create({ apiKey, subdomain });
        toast({
          id: "dbt-cloud-configuration",
          title: "dbt Cloud connected",
          message: "You can now use dbt Cloud to trigger your syncs",
          variant: "success",
        });
        await refetchCredentials();
        setApiKey("");
        setSubdomain("");
      }
    } catch (e) {
      toast({
        id: "dbt-cloud-configuration",
        title: "Error connecting to dbt Cloud",
        message: e.message ?? "There was an error saving your configuration.",
        variant: "error",
      });
      setError("Invalid API Key");
      Sentry.captureException(e);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Grid gap={8}>
            <Heading>dbt Cloud configuration</Heading>
            {credentials?.id ? (
              <ConnectedExtension credentials={credentials} testStatus={testStatus}>
                <Grid gap={3}>
                  <Text fontWeight="medium">
                    <Text fontWeight="semibold">Subdomain: </Text>
                    {credentials?.subdomain || "Not configured"}
                  </Text>
                </Grid>
              </ConnectedExtension>
            ) : (
              <>
                <FormField error={error ? error : undefined} label="dbt Cloud API key">
                  <TextInput
                    isDisabled={Boolean(credentials?.id)}
                    placeholder={credentials?.id ? "dbt Cloud API key" : undefined}
                    type="password"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </FormField>

                <FormField
                  description='If left empty, Hightouch will default to "cloud" as the subdomain.'
                  isRequired={false}
                  label="Custom subdomain"
                >
                  <TextInput
                    isDisabled={Boolean(credentials?.id)}
                    value={subdomain ?? ""}
                    onChange={(e) => setSubdomain(e.target.value)}
                  />
                </FormField>
              </>
            )}
          </Grid>
        </Container>
        <SidebarForm
          buttons={
            credentials?.id ? (
              <Permission>
                <Button
                  icon={ArrowPathIcon}
                  isDisabled={isTesting || isReTesting || isDeleting}
                  onClick={() => {
                    setTestStatus("loading");
                    testExtension({});
                  }}
                >
                  {isTesting || isReTesting ? "Testing..." : "Test connection"}
                </Button>
                <Button
                  icon={TrashIcon}
                  isDisabled={isDeleting || isTesting}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Disconnect
                </Button>
              </Permission>
            ) : (
              <Permission>
                <Button isLoading={isCreatingExtension} variant="primary" onClick={submit}>
                  Connect
                </Button>
              </Permission>
            )
          }
          docsUrl="extensions/dbt-cloud"
          name="dbt Cloud"
        />
      </Row>
      <DependentSyncsModal
        deleteExtension={() => deleteExtension({})}
        extensionName="dbt Cloud"
        isDeleting={extensionDeleting}
        open={isDeleting}
        scheduleType={ScheduleType.DBT_CLOUD}
        onClose={() => setIsDeleting(false)}
      />
    </PermissionProvider>
  );
};
