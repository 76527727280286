import { FC, useEffect, useState } from "react";

import { isEqual, omit, omitBy } from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { DeleteSourceWarning } from "src/components/modals/delete-source-warning";
import { OverageModal } from "src/components/modals/overage-modal";
import { SaveWarning } from "src/components/modals/save-warning";
import { Header, SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SourceForm } from "src/components/sources/setup";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { ListSourceTestStepsQueryVariables, ResourcePermissionGrant, useUpdateSourceV2Mutation } from "src/graphql";
import { Avatar } from "src/ui/avatar";
import { SquareBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { ChevronDownIcon, PlusIcon, RepeatIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Popout } from "src/ui/popout";
import { Tabs } from "src/ui/tabs";
import { SourceBadges, SourceConfig, SourceIcon, useSource } from "src/utils/sources";

import { useLabels } from "../../components/labels/use-labels";
import { SyncLog } from "./sync-log";

enum Tab {
  OVERVIEW = "Overview",
  CONFIGURATION = "Configuration",
  SYNC_LOG = "Sync log",
}

export const Source: FC = () => {
  const { source_id: id } = useParams<{ source_id: string }>();
  const { user } = useUser();
  const { addToast } = useToasts();
  const [config, setConfig] = useState<SourceConfig | undefined>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>();
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);

  const [tab, setTab] = useState<Tab | undefined>();
  const [deleteSource, setDeleteSource] = useState<boolean>(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

  const { data: source, loading: loadingSource, refetch } = useSource(id);

  const { results: testResults, steps: testSteps, getTestSteps, runTest, timeElapsed } = useSourceTesting();

  const { labels } = useLabels();

  const currentLabels = source?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);

  const onUpdate = () => {
    addToast(`Source updated successfully!`, {
      appearance: "success",
    });
  };

  const { isLoading: updateLoading, mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const saveName = async (name: string) => {
    if (!id) {
      return;
    }

    await updateSource({
      id,
      object: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const save = async () => {
    if (!id) {
      return;
    }

    let updatedConfig = config;

    if (tunnelId) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }

    await updateSource({
      id,
      object: {
        updated_by: user?.id != null ? String(user?.id) : undefined,
        tunnel_id: tunnelId ? tunnelId : null,
        credential_id: credentialId != null ? String(credentialId) : undefined,
        config: updatedConfig,
        plan_in_warehouse: lightningEnabled || undefined,
        plan_in_warehouse_config: plannerDatabase ? { plannerDatabase } : undefined,
      },
    });

    refetch();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateSource({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      addToast(`Label update failure. ${error.message}`, { appearance: "error", autoDismiss: false });
    }
  };

  const configChanged = !isEqual(
    config
      ? omit(
          omitBy(config, (v) => v === undefined),
          ["methodKey"],
        )
      : undefined,
    omit(source?.config, ["methodKey"]) ?? undefined,
  );
  const tunnelChanged = !isEqual(tunnelId, source?.tunnel?.id ?? undefined);
  const credentialChanged = !isEqual(credentialId, source?.credential_id ?? undefined);
  const lightningEnabledChanged = !isEqual(lightningEnabled, source?.plan_in_warehouse ?? undefined);
  const plannerDatabaseChanged = !isEqual(plannerDatabase, source?.plan_in_warehouse_config?.plannerDatabase ?? undefined);

  const dirty = tunnelChanged || configChanged || credentialChanged || lightningEnabledChanged || plannerDatabaseChanged;
  const complete = tunnelId !== null;

  const TABS = [source?.definition?.isSampleDataSource ? Tab.OVERVIEW : Tab.CONFIGURATION];
  if (source?.plan_in_warehouse) {
    TABS.push(Tab.SYNC_LOG);
  }

  useEffect(() => {
    if (!tab && source) {
      setTab(source?.definition?.isSampleDataSource ? Tab.OVERVIEW : Tab.CONFIGURATION);
    }

    setConfig(source?.config ?? undefined);
    setTunnelId(source?.tunnel?.id ?? undefined);
    setCredentialId(source?.credential_id ?? undefined);
    setLightningEnabled(source?.plan_in_warehouse ?? undefined);
    setPlannerDatabase(source?.plan_in_warehouse_config?.plannerDatabase ?? undefined);
  }, [source]);

  const variables: ListSourceTestStepsQueryVariables = {
    sourceType: source?.definition?.type,
    sourceId: String(source?.id),
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled ? { plannerDatabase } : undefined,
  };

  if (loadingSource) {
    return <PageSpinner />;
  }

  const updatedByUsername = source?.updated_by_user?.name || source?.created_by_user?.name;

  if (!loadingSource && !source) {
    return <Warning subtitle="It may have been deleted" title="Source not found" />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Sources", link: "/sources" }, { label: source?.name ?? "" }]} size="medium">
          <Column sx={{ width: "100%", mb: 6 }}>
            <Header
              rightToolbar={[
                <Permission
                  key={1}
                  permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Delete], resource_id: id }]}
                >
                  <Button variant="secondary" onClick={() => setDeleteSource(true)}>
                    Delete
                  </Button>
                </Permission>,
              ].filter(isPresent)}
              title={source?.name ?? ""}
              onNameChange={saveName}
            />
            <Row sx={{ alignItems: "center", mt: -6 }}>
              <Row sx={{ alignItems: "center", mr: 4, pr: 4, borderRight: "small" }}>
                <SourceIcon source={source} sx={{ width: "20px" }} />
                <Text sx={{ ml: 2, fontWeight: "semi" }}>{source?.definition?.name}</Text>
                <SourceBadges source={source} />
              </Row>
              <Row sx={{ alignItems: "center" }}>
                <Text sx={{ mr: 1, color: "base.6" }}>Last updated:</Text>
                <Text sx={{ mr: 1 }}>
                  {moment(source?.updated_at || source?.created_at).calendar()}
                  {updatedByUsername && " by"}
                </Text>
                {updatedByUsername && <Avatar name={updatedByUsername} />}
              </Row>
              <Row sx={{ borderLeft: "small", ml: 4, pl: 4, alignItems: "center" }}>
                <Text sx={{ mr: 1, color: "base.7" }}>Slug:</Text>
                <DisplaySlug currentSlug={source?.slug} />
              </Row>
              {labelKeys.length > 0 ? (
                <Row sx={{ height: "100%", alignItems: "center", pl: 4, ml: 4, borderLeft: "small" }}>
                  <Popout
                    content={({ close }) => (
                      <>
                        <Labels labels={currentLabels} />
                        <Permission
                          permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            sx={{ mt: 4 }}
                            variant="secondary"
                            onClick={() => {
                              setIsEditLabelModalOpen(true);
                              close();
                            }}
                          >
                            Edit labels
                          </Button>
                        </Permission>
                      </>
                    )}
                    contentSx={{ p: 3, minWidth: "90px" }}
                  >
                    <Text sx={{ mr: 1 }}>Labels</Text>
                    <SquareBadge>{Object.keys(source?.tags || {}).length}</SquareBadge>
                    <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                  </Popout>
                </Row>
              ) : (
                <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                  <Button
                    iconBefore={<PlusIcon color="gray.700" size={14} />}
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      setIsEditLabelModalOpen(true);
                    }}
                  >
                    Add labels
                  </Button>
                </Row>
              )}
            </Row>
          </Column>
          <Row>
            <Column sx={{ width: "100%" }}>
              <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

              {tab === Tab.CONFIGURATION && source && (
                <Row sx={{ alignItems: "flex-start" }}>
                  <Grid gap={8} sx={{ flexGrow: 1, mr: 8 }}>
                    {source.definition && (
                      <SourceForm
                        //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
                        key={`${updateLoading}`}
                        hideSecret
                        config={config}
                        credentialId={credentialId}
                        definition={source.definition}
                        hasSetupLightning={Boolean(source.plan_in_warehouse)}
                        isSetup={false}
                        lightningEnabled={lightningEnabled}
                        plannerDatabase={plannerDatabase}
                        setConfig={setConfig}
                        setCredentialId={setCredentialId}
                        setLightningEnabled={setLightningEnabled}
                        setPlannerDatabase={setPlannerDatabase}
                        setTunnelId={setTunnelId}
                        sourceId={id}
                        tunnelId={tunnelId}
                      />
                    )}
                  </Grid>
                  <SidebarForm
                    buttons={
                      <>
                        <Permission
                          permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            disabled={!dirty || !complete}
                            label="Save changes"
                            loading={updateLoading}
                            sx={{ width: "100%" }}
                            onClick={save}
                          />
                        </Permission>
                        {!source.definition?.disableTest && config && id ? (
                          <Permission
                            permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                          >
                            <Button
                              disabled={!complete}
                              label="Test connection"
                              sx={{ width: "100%" }}
                              variant="secondary"
                              onClick={async () => {
                                await getTestSteps(variables);
                                runTest(variables);
                                setTestConnectionModalOpen(true);
                              }}
                            />
                          </Permission>
                        ) : null}
                      </>
                    }
                    docsUrl={source.definition?.docs ?? ""}
                    message={`Can you help me with ${source.definition?.name}? `}
                    name={source.definition?.name ?? ""}
                  />
                </Row>
              )}

              {tab === Tab.OVERVIEW && source && (
                <Row sx={{ alignItems: "flex-start" }}>
                  <Grid gap={8} sx={{ flexGrow: 1, mr: 8 }}>
                    <Text>{source.definition?.name}</Text>
                    <Text>{source.definition?.longPitch}</Text>
                  </Grid>
                  <SidebarForm docsUrl={source.definition?.docs ?? ""} name={source.definition?.name ?? ""} />
                </Row>
              )}

              {tab === Tab.SYNC_LOG && source && (
                <Permission permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update], resource_id: id }]}>
                  <SyncLog source={source} />
                </Permission>
              )}
            </Column>
          </Row>
        </Page>
      </PermissionProvider>

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels ?? {}}
        loading={updateLoading}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <OverageModal />

      {source && (
        <Modal
          footer={
            <>
              <Button
                variant={testResults?.success !== false && !dirty ? undefined : "secondary"}
                onClick={() => {
                  setTestConnectionModalOpen(false);
                }}
              >
                Close
              </Button>
              {testResults?.success === false ? (
                <Button
                  iconBefore={<RepeatIcon color="white" size={14} />}
                  onClick={() => {
                    runTest(variables);
                  }}
                >
                  Test again
                </Button>
              ) : dirty ? (
                <Button
                  disabled={!testResults?.success}
                  label="Save changes"
                  loading={updateLoading}
                  onClick={async () => {
                    await save();
                    setTestConnectionModalOpen(false);
                  }}
                />
              ) : null}
            </>
          }
          isOpen={testConnectionModalOpen}
          sx={{ maxWidth: "800px", width: "90%" }}
          title={`Test connection to ${source.definition?.name}`}
          onClose={() => {
            setTestConnectionModalOpen(false);
          }}
        >
          <Testing
            config={config}
            credentialId={credentialId}
            isSetup={false}
            plannerDatabase={plannerDatabase}
            results={testResults}
            sourceDefinition={source.definition}
            steps={testSteps}
            timeElapsed={timeElapsed}
          />
        </Modal>
      )}

      <SaveWarning dirty={dirty} />
      {deleteSource && source && (
        <DeleteSourceWarning source={source} sourceDefinition={source.definition} onClose={() => setDeleteSource(false)} />
      )}
    </>
  );
};
