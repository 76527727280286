import { FC, useEffect, useState } from "react";

import { Alert, FormField, Link, MultiSelect, TextInput } from "@hightouchio/ui";
import * as diff from "diff";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from "theme-ui";
import { isPresent } from "ts-extras";

import { Diff } from "src/components/diff";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { ChangelogQuery, ResourcePermissionGrant, ResourceToPermission, useChangelogQuery } from "src/graphql";
import { Column, Container } from "src/ui/box";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";

export const AuditLog: FC = () => {
  return (
    <Settings route="audit-log">
      <Container center={false} size="medium" sx={{ mx: "auto" }}>
        <Grid gap={12}>
          <PermissionProvider
            permissions={[
              {
                grants: [ResourcePermissionGrant.Read],
                resource: ResourceToPermission.Workspace,
              },
            ]}
          >
            <General />
          </PermissionProvider>
        </Grid>
      </Container>
    </Settings>
  );
};

type ChangelogItem = ChangelogQuery["auditLog"]["items"][0];

const General: FC = () => {
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const { auditLogs } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryUsers = searchParams.get("users");
  const queryResources = searchParams.get("resources");
  const queryResourceName = searchParams.get("resource_name");
  const queryStartDate = searchParams.get("start_date");
  const queryEndDate = searchParams.get("end_date");

  let queryParamFilteredUsers: string[] = [];

  if (queryUsers !== null) {
    try {
      queryParamFilteredUsers = queryUsers?.split(",") || [];
    } catch (e) {
      queryParamFilteredUsers = [];
    }
  }

  let queryParamFilteredResources;
  if (queryResources !== null) {
    try {
      queryParamFilteredResources = queryResources?.split(",") || [];
    } catch (e) {
      queryParamFilteredResources = [];
    }
  }

  const { offset, limit, page, setPage } = useTableConfig({ limit: 10 });
  const [resourceName, setResourceName] = useState<string | null>(queryResourceName);
  const [startDate, setStartDate] = useState<string | null>(queryStartDate);
  const [endDate, setEndDate] = useState<string | null>(queryEndDate);
  const [selectedChangelogItem, setSelectedChangelogItem] = useState<ChangelogItem | null>();
  const [filteredResources, setFilteredResources] = useState<string[]>(queryParamFilteredResources || []);
  const [filteredUsers, setFilteredUsers] = useState<string[]>(queryParamFilteredUsers || []);
  const { data, isFetching } = useChangelogQuery(
    {
      filters: {
        resource_name: resourceName,
        start_date: startDate,
        end_date: endDate,
        user_ids: filteredUsers,
        filtered_resources: filteredResources,
        offset,
      },
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    setSelectedChangelogItem(null);
  }, [data?.auditLog]);

  useEffect(() => {
    setPage(0);
    if (filteredResources?.length > 0) {
      searchParams.set("resources", filteredResources.join(","));
    } else {
      searchParams.delete("resources");
    }

    if (filteredUsers?.length > 0) {
      searchParams.set("users", filteredUsers.join(","));
    } else {
      searchParams.delete("users");
    }

    if (resourceName) {
      searchParams.set("resource_name", resourceName);
    } else {
      searchParams.delete("resource_name");
    }

    if (startDate) {
      searchParams.set("start_date", startDate);
    } else {
      searchParams.delete("start_date");
    }

    if (endDate) {
      searchParams.set("end_date", endDate);
    } else {
      searchParams.delete("end_date");
    }

    setSearchParams(searchParams);
  }, [filteredResources, startDate, resourceName, endDate, filteredUsers]);

  const columns = [
    {
      name: "Date",
      key: "created_at",
      cell: (created_at) => <>{formatDatetime(created_at)}</>,
    },
    {
      name: "Member",
      key: "user_name",
    },
    {
      name: "Action",
      key: "action",
    },
    {
      name: "Resource type",
      key: "resource",
    },
    {
      name: "Resource name",
      key: "resource_name",
    },
  ];

  const sortedResources = sortBy(data?.auditLog.resources);

  if (workspace?.organization?.plan?.sku !== "business_tier" && !user?.is_admin && !auditLogs) {
    return (
      <Alert
        actionText="Upgrade plan"
        message={
          <>
            Upgrade to Business Tier to access this feature. Audit logs include a searchable history of all actions performed in
            this workspace, such as changes to syncs and models. You can use these logs to diagnose broken syncs, roll back
            changes, and monitor workspace security. Read our{" "}
            <Link href="https://hightouch.com/docs/workspace-management/audit-logs">docs</Link> to learn more.
          </>
        }
        title="Audit logs are not available in your workspace."
        variant="warning"
        onAction={() => {
          navigate(`/${workspace?.slug}/settings/billing`);
        }}
      />
    );
  }

  const users = workspace?.all_memberships.filter((m) => m.user).map((m) => m.user) || [];
  const sortedUsers = sortBy(users, "name").filter(isPresent);

  return (
    <>
      <Column sx={{ gap: 4 }}>
        {!auditLogs && workspace?.organization?.plan?.sku !== "business_tier" && user?.is_admin && (
          <Alert
            message="Only privileged users like you can see this page."
            title="Internal notice: audit logs are not enabled for this workspace."
            variant="warning"
          />
        )}
        <Grid width={[150, 150, 120]}>
          <FormField label="Workspace member">
            <MultiSelect
              optionLabel={(user) => user?.name ?? user?.email ?? ""}
              optionValue={(user) => String(user?.id ?? "")}
              options={sortedUsers}
              placeholder="Filter members..."
              value={filteredUsers}
              onChange={setFilteredUsers}
            />
          </FormField>
          <FormField label="Resource type">
            <MultiSelect
              optionLabel={(resource) => resource}
              optionValue={(resource) => resource}
              options={sortedResources}
              placeholder="Filter resources..."
              value={filteredResources}
              onChange={setFilteredResources}
            />
          </FormField>
          <FormField label="Resource name">
            <TextInput placeholder="Search..." value={resourceName || ""} onChange={(e) => setResourceName(e.target.value)} />
          </FormField>
          <FormField label="Start date">
            <TextInput type="date" value={startDate || ""} onChange={(e) => setStartDate(e.target.value)} />
          </FormField>
          <FormField label="End date">
            <TextInput placeholder="End date" type="date" value={endDate || ""} onChange={(e) => setEndDate(e.target.value)} />
          </FormField>
        </Grid>
      </Column>
      <Grid columns={["2fr 1fr"]} gap={4}>
        <Column>
          <Table
            columns={columns}
            data={data?.auditLog.items}
            highlight={selectedChangelogItem?.created_at}
            loading={isFetching}
            placeholder={{
              title: "No items in the audit log for your workspace",
              body: "Try changing your filters to see more items",
              error: "Failed to load audit log, please try again",
            }}
            primaryKey="id"
            onRowClick={(row) => {
              setSelectedChangelogItem(row);
            }}
          />
          <Pagination compact count={data?.auditLog.total} label="entries" page={page} rowsPerPage={limit} setPage={setPage} />
        </Column>
        <Column sx={{ borderLeft: "small", pl: 4 }}>
          {selectedChangelogItem ? (
            <ChangelogItemDiff key={selectedChangelogItem.id} item={selectedChangelogItem} />
          ) : (
            "Click on an entry in the audit log to view details."
          )}
        </Column>
      </Grid>
    </>
  );
};

const ChangelogItemDiff = ({ item }: { item: ChangelogItem }) => {
  if (item.new === null) {
    const diffs = diff.diffJson(item.old, "");
    return <Diff diffs={diffs} />;
  }

  const diffs = diff.diffJson(item.old || "", item.new);
  return <Diff diffs={diffs} />;
};
