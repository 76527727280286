import { CSSProperties, Fragment, useState, FC } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { isEqual } from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text, ThemeUIStyleObject } from "theme-ui";
import * as Yup from "yup";

import { Audience, VisualQueryFilter } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Box, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { XIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select/select";
import { Toggle } from "src/ui/toggle";

import { Column, SplitTestDefinition } from "../../../../backend/lib/query/visual/types";
import { Indices } from "../../../../design";

type Props = {
  audience: Audience;
  data: VisualQueryFilter["splitTestDefinition"];
  onSave: (data: SplitTestDefinition | undefined) => Promise<void>;
};

const validationSchema = Yup.object().shape({
  groupColumnName: Yup.string().required("Group column name required"),
  samplingType: Yup.string().required(),
  splits: Yup.array().of(
    Yup.object().shape({
      groupValue: Yup.string().required("Split group name required"),
      percentage: Yup.number()
        .min(1, "Split percentages must be between 1 and 99")
        .max(99, "Split percentages must be between 1 and 99")
        .required("Split group percentage required"),
    }),
  ),
  stratificationVariables: Yup.array().of(
    Yup.mixed()
      .transform((variableObj) => (Object.keys(variableObj).length ? variableObj : undefined))
      .required("Stratification variable required"),
  ),
});

export const badgeStyles: CSSProperties = {
  position: "relative",
  right: -6,
};

export const tooltipStyles: ThemeUIStyleObject = {
  bg: "white",
  color: "secondary",
  overflow: "visible",
  boxShadow: "large",
};

export const caretStyles: ThemeUIStyleObject = {
  fontSize: 0,
  lineHeight: 2,
  px: 3,
  ":hover": {
    "::before": {
      content: "''",
      position: "absolute",
      top: "-14px",
      left: "16px",
      width: "10px",
      height: "10px",
      bg: "white",
      transform: "rotate(45deg)",
      zIndex: Indices.Modal,
    },
  },
};

function getTotalPercentage(splitPercentages: number[]): number {
  return splitPercentages.reduce((sum, percentage) => sum + percentage, 0);
}

export const Splits: FC<Readonly<Props>> = ({ audience, data: splitTestDefinition, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [splitsEnabled, setSplitsEnabled] = useState(Boolean(splitTestDefinition));

  const { addToast } = useToasts();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    watch,
  } = useForm<SplitTestDefinition>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      groupColumnName: splitTestDefinition?.groupColumnName || "",
      samplingType: splitTestDefinition?.samplingType || "non-stratified",
      splits: splitTestDefinition?.splits || [
        { groupValue: "", percentage: 50 },
        { groupValue: "", percentage: 50 },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Circular type problem with Column[]
      stratificationVariables: splitTestDefinition?.stratificationVariables || [],
    },
  });

  const { fields: stratificationVariableFields, append: stratificationVariableAppend, remove: stratificationVariableRemove } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    useFieldArray({
      control,
      name: "stratificationVariables",
    });

  const {
    fields: splitGroups,
    append: splitGroupAppend,
    remove: splitGroupRemove,
  } = useFieldArray({
    control,
    name: "splits",
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const groupColumnName = watch("groupColumnName");
  const samplingType = watch("samplingType");
  const splits = watch("splits");
  const stratificationVariables = watch("stratificationVariables");

  // TODO: This is a manual validation hack. Was unable to get consistent
  // error behavior while throwing this error with react-hook-form + yup validation.
  const totalPercentage = getTotalPercentage(splits?.map((split) => split?.percentage || 0) || []);
  const isValidCurrentPercentage = totalPercentage === 100;

  const newSplitTestDefinition = splitsEnabled
    ? {
        groupColumnName,
        samplingType,
        splits,
        stratificationVariables,
      }
    : undefined;

  const saveSplitsConfig = async (data: SplitTestDefinition | undefined) => {
    setSaveLoading(true);

    try {
      await onSave?.(data);
    } catch (error) {
      Sentry.captureException(error);
      addToast("Failed to save splits configuration.", { appearance: "error" });
    }

    setSaveLoading(false);
  };

  const handleFormErrors = () =>
    addToast("There was an error saving your splits configuration.", {
      appearance: "error",
    });

  const handleSave = async (formData: SplitTestDefinition) => {
    if (!isValidCurrentPercentage) {
      handleFormErrors();
    } else {
      saveSplitsConfig(splitsEnabled ? formData : undefined);
    }
  };

  return (
    <Row sx={{ alignItems: "start", width: "100%", justifyContent: "space-between" }}>
      <Grid gap={6} sx={{ maxWidth: "800px" }}>
        <Row sx={{ alignItems: "center" }}>
          <Heading>Splits</Heading>
          <Toggle
            sx={{ ml: 4 }}
            value={splitsEnabled}
            onChange={(enabled) => {
              setSplitsEnabled(enabled);
            }}
          />
        </Row>
        <Text>
          With splits, you may divide the audience into multiple defined groups based on specific percentages. These groups can
          be stratified based on multiple variables to ensure that certain subgroups are equally represented in the splits.
        </Text>
        {splitsEnabled && (
          <>
            <Section divider>
              <Field
                description="This column will be created to define which group a particular row belongs to (e.g. group_name or test_group)."
                error={errors.groupColumnName && errors.groupColumnName.message}
                label="Group column name"
                size="large"
              >
                <Input
                  {...register("groupColumnName" as const)}
                  error={Boolean(errors.groupColumnName)}
                  placeholder="Enter a name for your group column (e.g. test_group)..."
                  sx={{ width: "360px" }}
                />
              </Field>
            </Section>
            <Section divider>
              <Field
                description="This defines how your groups should be split in terms of quantity and percentages. For accurate splits, Hightouch recommends a minimum audience size of 100 members."
                label="Splits"
                size="large"
              >
                {splits.length && (
                  <Grid gap={2} sx={{ mb: 4 }}>
                    {splitGroups.map(({ id }, index) => (
                      <Fragment key={id}>
                        <Row>
                          <Input
                            {...register(`splits.${index}.groupValue` as const)}
                            error={Boolean(errors.splits?.[index]?.groupValue)}
                            placeholder="Enter a group value..."
                            sx={{ maxWidth: "280px", mr: 2 }}
                          />
                          <Input
                            {...register(`splits.${index}.percentage` as const, { valueAsNumber: true })}
                            error={Boolean(errors.splits?.[index]?.percentage)}
                            min="1"
                            placeholder="Enter a percentage..."
                            sx={{ maxWidth: "280px", mr: 2 }}
                            type="number"
                          />
                          {splits.length > 2 && (
                            <Button variant="plain" onClick={() => splitGroupRemove(index)}>
                              <XIcon color="base.6" size={18} />
                            </Button>
                          )}
                        </Row>
                        {errors.splits?.[index]?.groupValue && (
                          <FieldError error={errors.splits?.[index]?.groupValue?.message} />
                        )}
                        {errors.splits?.[index]?.percentage && (
                          <FieldError error={errors.splits?.[index]?.percentage?.message} />
                        )}
                      </Fragment>
                    ))}
                    {isSubmitted && !isValidCurrentPercentage && <FieldError error="Percentages must add up to 100%" />}
                  </Grid>
                )}
                <Button
                  label="Add split"
                  variant="secondary"
                  onClick={() => splitGroupAppend({ groupValue: "", percentage: 1 })}
                />
              </Field>
            </Section>
            <Section>
              <Row sx={{ alignItems: "center", mb: 6 }}>
                <Heading>Stratified Sampling (Advanced)</Heading>
                <Box style={badgeStyles}>
                  <Badge
                    sx={caretStyles}
                    tooltip="This feature is currently in beta. Any feedback is greatly appreciated."
                    tooltipSx={tooltipStyles}
                    variant="blue"
                  >
                    Beta
                  </Badge>
                </Box>
                <Toggle
                  sx={{ ml: 4 }}
                  value={samplingType === "stratified"}
                  onChange={(enabled) => {
                    setValue("stratificationVariables", []);

                    if (enabled) {
                      stratificationVariableAppend({} as Column);
                    }

                    setValue("samplingType", enabled ? "stratified" : "non-stratified");
                  }}
                />
              </Row>
              <Text>
                Stratified sampling allows users to stratify their groups such that each group will contain an equal allocation
                of values from specified stratification variables.{" "}
                <i>Please note that stratified sampling can only be used with one-time syncs.</i>
              </Text>
              {samplingType === "stratified" && (
                <Field
                  description="The below columns are used to stratify each group. For example, you may want to make sure that your groups are
                  stratified along the age factor."
                  label="Stratification variables"
                  size="large"
                  sx={{ mt: 5 }}
                >
                  {Boolean(stratificationVariables.length) && (
                    <Grid gap={2} sx={{ mb: 4 }}>
                      {stratificationVariableFields.map(({ id }, index) => (
                        <Controller
                          key={id}
                          control={control}
                          name={`stratificationVariables.${index}`}
                          render={({ field }) => (
                            <>
                              <Row>
                                <Select
                                  isError={Boolean(errors.stratificationVariables?.[index])}
                                  options={audience?.parent?.filterable_audience_columns.map((filterableColumn) => ({
                                    label: filterableColumn.name,
                                    value: filterableColumn.column_reference,
                                  }))}
                                  placeholder="Select a column..."
                                  sx={{ maxWidth: "360px", mr: 2 }}
                                  value={field.value}
                                  onChange={(option) => field.onChange(option.value)}
                                />
                                {stratificationVariables.length > 1 && (
                                  <Button variant="plain" onClick={() => stratificationVariableRemove(index)}>
                                    <XIcon color="base.6" size={18} />
                                  </Button>
                                )}
                              </Row>
                              {errors.stratificationVariables?.[index] && (
                                <FieldError error={errors.stratificationVariables?.[index]?.["message"]} />
                              )}
                            </>
                          )}
                        />
                      ))}
                    </Grid>
                  )}
                  <Button
                    label="Add variable"
                    variant="secondary"
                    onClick={() => {
                      stratificationVariableAppend({} as Column);
                    }}
                  />
                </Field>
              )}
            </Section>
          </>
        )}
      </Grid>
      <Grid gap={2} sx={{ width: "208px", position: "sticky", top: 24, ml: 5 }}>
        <Button
          disabled={isEqual(audience?.visual_query_filter?.splitTestDefinition, newSplitTestDefinition)}
          loading={saveLoading}
          sx={{ width: "100%" }}
          onClick={handleSubmit(handleSave, handleFormErrors)}
        >
          Save
        </Button>
      </Grid>
    </Row>
  );
};
