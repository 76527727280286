import { FC, ReactNode } from "react";

import * as Sentry from "@sentry/react";
import { capitalize } from "lodash";
import { useToasts } from "react-toast-notifications2";
import { Box, Grid, Text } from "theme-ui";

import { Sidebar } from "src/components/page";
import { Permission } from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import {
  ResourceToPermission,
  DraftsQuery,
  useUpdateDraftsStatusMutation,
  DraftStatus,
  DraftOperation,
  useDraftsQuery,
  ResourcePermissionGrant,
  useDeleteDraftMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { ExternalLinkIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { formatDatetime } from "src/utils/time";

interface DraftPageProps {
  children: ReactNode;
  resourceId: string;
  resourceType: ResourceToPermission;
  draft: DraftsQuery["drafts"][0];
  onApprove: () => void;
  onWithdraw: (deny: boolean) => void;
  withdrawing: boolean;
  dependentResource?: { resourceId: string; resourceType: ResourceToPermission };
  title: string;
  link: string;
}

export const DraftPage: FC<DraftPageProps> = ({
  draft,
  resourceType,
  resourceId,
  children,
  onApprove,
  onWithdraw,
  withdrawing,
  dependentResource,
  title,
  link,
}) => {
  const { user } = useUser();
  const { addToast } = useToasts();
  const { mutateAsync: updateDraftStatus, isLoading: updating } = useUpdateDraftsStatusMutation();
  const { mutateAsync: deleteMutation, isLoading: deleting } = useDeleteDraftMutation();

  const { data: drafts } = useDraftsQuery(
    {
      resourceId: dependentResource?.resourceId.toString() ?? "",
      resourceType: dependentResource?.resourceType ?? "",
      status: "pending",
    },
    {
      enabled: Boolean(dependentResource?.resourceId) && Boolean(dependentResource?.resourceType),
    },
  );
  const dependentDraft = drafts?.drafts?.[0];

  const capitalizedResourceType = capitalize(resourceType);

  const approve = () => {
    analytics.track(`Draft ${capitalizedResourceType} Approved`, {
      sync_id: resourceId,
      draft_id: draft.id,
    });

    onApprove();
  };

  const deleteDraft = async () => {
    try {
      deleteMutation({
        draftId: draft.id,
      });
      analytics.track("Draft Deleted", {
        resource_id: resourceId,
        draft_id: draft.id,
        resource_type: resourceType,
      });
      addToast("Draft deleted", {
        appearance: "success",
      });
    } catch (err) {
      Sentry.captureException(err);
      addToast("Failed to delete draft", {
        appearance: "error",
      });
    }
  };

  const onDeny = async () => {
    analytics.track("Draft Denied", {
      resource_id: resourceId,
      draft_id: draft.id,
      resource_type: resourceType,
    });

    onWithdraw(true);
  };

  const withdraw = async () => {
    analytics.track("Draft Withdrawn", {
      resource_id: resourceId,
      draft_id: draft.id,
      resource_type: resourceType,
    });

    onWithdraw(false);
  };

  const handleApprove = async () => {
    const extraDraftIds = dependentDraft ? [dependentDraft.id] : [];
    try {
      await updateDraftStatus({
        draftIds: [...extraDraftIds, draft.id],
        status: DraftStatus.Approved,
      });
      approve();
      addToast(`${capitalizedResourceType} updated successfully!`, {
        appearance: "success",
      });
    } catch (err) {
      addToast("Failed to approve draft", {
        appearance: "error",
      });
      Sentry.captureException(err);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: "flex-start" }}>
      <Row sx={{ mb: 4, width: "100%", borderBottom: "small", pb: 4, justifyContent: "space-between", alignItems: "center" }}>
        <Column>
          <Row sx={{ mb: 2, alignItems: "center" }}>
            <Heading>{title}</Heading>
            <Link sx={{ ml: 2 }} to={draft.operation === DraftOperation.Create ? link : `${link}?editing=true`}>
              <ExternalLinkIcon color="base.6" size={24} />
            </Link>
          </Row>
          <Row sx={{ color: "base.6" }}>
            {draft.operation === DraftOperation.Create ? "Changed" : "Created"} by {draft.created_by_user?.name || "unknown"} on{" "}
            {formatDatetime(draft.created_at)}
          </Row>
        </Column>
      </Row>
      <Row>
        <Grid gap={8} sx={{ flex: 1 }}>
          {dependentResource && dependentDraft && (
            <Message>
              By approving this draft, <Link to={`/models/${dependentResource?.resourceId}`}>this</Link> dependent model will
              also be approved.
            </Message>
          )}

          {draft.comment && (
            <Field label="Draft comment" size="large">
              <Text sx={{ color: "base.6" }}>{draft.comment || "No comment"}</Text>
            </Field>
          )}

          {children}
        </Grid>

        <Sidebar>
          <Box>
            <Grid gap={3} mb={10}>
              <Permission
                permissions={[
                  {
                    resource: resourceType,
                    resource_id: resourceId,
                    grants: [ResourcePermissionGrant.Approve],
                  },
                ]}
              >
                <Button loading={updating} sx={{ width: "100%" }} onClick={handleApprove}>
                  Approve & publish
                </Button>
              </Permission>
              {draft.created_by === user?.id && draft.operation !== DraftOperation.Create && (
                <Button loading={deleting} sx={{ width: "100%" }} variant="red" onClick={deleteDraft}>
                  Delete draft
                </Button>
              )}
              <Button
                loading={withdrawing}
                sx={{ width: "100%" }}
                variant="secondary"
                onClick={draft.created_by === user?.id ? withdraw : onDeny}
              >
                {draft.created_by === user?.id ? "Withdraw request" : "Deny"}
              </Button>
            </Grid>
          </Box>
        </Sidebar>
      </Row>
    </Box>
  );
};
