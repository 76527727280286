import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useFacebookConversionsColumnsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, convertType } from "src/utils/destinations";

export const validation = Yup.object().shape({
  testEventCode: Yup.string().notRequired(),
  mode: Yup.string().required().default("upsert"),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  disablePIIHashing: Yup.boolean().notRequired(),
  actionSource: Yup.string().notRequired(),
});

const ACTIONS = [
  { label: "Email", value: "email" },
  { label: "Website", value: "website" },
  { label: "Phone Call", value: "phone_call" },
  { label: "Chat", value: "chat" },
  { label: "Physical Store", value: "physical_store" },
  { label: "System Generated", value: "system_generated" },
  { label: "Other", value: "other" },
];

const requiredFields = ["event_name", "event_time"];

export const FacebookForm: FC = () => {
  const { errors, config, setConfig, destination } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useFacebookConversionsColumnsQuery({
    destinationId: String(destination?.id),
    object: "Event",
  });

  const columns = columnsData?.facebookConversionsDescribeObject?.fields;

  const columnOptions =
    columns?.map((a) => ({
      label: a?.name,
      value: a?.id,
      type: convertType(a?.type, {}),
      required: requiredFields?.includes(a?.id) ? true : undefined,
    })) || [];

  return (
    <>
      <Section>
        <MappingsField error={columnsError?.message} loading={loadingColumns} options={columnOptions} reload={getColumns} />
      </Section>

      <Section>
        <MappingsField isCustom />
      </Section>

      <Section>
        <Grid gap={8}>
          <Field
            optional
            description="This field allows you to set the action source of your event to a static value. If you mapped a column to action_source above, the value will overwrite this selection."
            error={errors?.actionSource}
            label="What is the action source of your event?"
            size="large"
          >
            <Select
              isError={errors?.actionSource}
              options={ACTIONS}
              placeholder="Select an action source..."
              value={config?.actionSource ? ACTIONS?.find((s) => config?.actionSource === s.value) : null}
              width="340px"
              onChange={(selected) => setConfig({ ...config, actionSource: selected?.value })}
            />
          </Field>

          <Field label="Would you like to pass a test event code for testing purposes?" optional={true} size="large">
            <Input
              defaultValue={config?.testEventCode}
              placeholder="Enter a test code..."
              sx={{ width: "180px" }}
              onChange={(testEventCode) => setConfig({ ...config, testEventCode })}
            />
          </Field>

          <Field
            description="By default, Hightouch automatically hashes values mapped to certain user data fields. For more information, check out the documentation."
            label="Would you like Hightouch to automatically hash your PII data?"
            size="large"
          >
            <RadioGroup
              options={[
                { label: "Yes, automatically hash PII for me", value: undefined },
                { label: "No, I will hash it myself", value: true },
              ]}
              value={config?.disablePIIHashing}
              onChange={(disablePIIHashing) => {
                setConfig({ ...config, disablePIIHashing });
              }}
            />
          </Field>
        </Grid>
      </Section>
    </>
  );
};

export default {
  form: FacebookForm,
  validation,
};
