import { ReactElement, ReactNode } from "react";

import { Box, Grid, Text } from "theme-ui";
import * as Yup from "yup";

import { FormkitModel } from "src/formkit/components/formkit-context";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { MappingsField } from "../../mappings-field";
import { PIIHashingField } from "../../pii-hashing-field";

const MAPPINGS = [
  { label: "Email", value: "email", type: StandardFieldType.STRING },
  { label: "Phone", value: "phone", type: StandardFieldType.STRING },
  { label: "First Name", value: "firstName", type: StandardFieldType.STRING },
  { label: "Last Name", value: "lastName", type: StandardFieldType.STRING },
  { label: "Country", value: "country", type: StandardFieldType.STRING },
  { label: "Zip", value: "zip", type: StandardFieldType.STRING },
];

// Some fields count as user identifiers: contact info and physical address info.
const USER_IDENTIFIERS = ["email", "phone", "userId", "mobileId", "country", "zip"];

const PHONE_COUNTRY_COLUMN_OPTION = { label: "Phone Country ISO Code", value: "phoneCountry", type: StandardFieldType.STRING };

export function CustomerMatchUserListForm({
  model,
  config,
  setConfig,
  errors,
}: Readonly<{
  model: FormkitModel | undefined;
  config: Record<string, unknown>;
  setConfig: (configuration: Record<string, unknown>) => void;
  errors?: Record<string, ReactNode | Error>;
}>): ReactElement<any, any> {
  const columnOptions =
    config?.userListType === "CRM_ID"
      ? [
          {
            label: "Third Party User Id",
            value: "userId",
            type: StandardFieldType.STRING,
          },
          ...MAPPINGS,
        ]
      : config?.userListType === "MOBILE_ADVERTISING_ID"
      ? [{ label: "Mobile Id", value: "mobileId", type: StandardFieldType.STRING }, ...MAPPINGS]
      : [...MAPPINGS];
  if (config?.cleanPhone) {
    columnOptions.push(PHONE_COUNTRY_COLUMN_OPTION);
  }

  return (
    <>
      <Section>
        <Grid gap={8}>
          <Field
            optional
            description="If this name is not specified, Hightouch defaults to using the model name to create the audience."
            label="Would you like a custom name for the user list?"
            size="large"
          >
            <Input
              defaultValue={config?.userListName as string}
              placeholder={model?.name as string}
              sx={{ width: "240px" }}
              onChange={(userListName) => setConfig({ ...config, userListName })}
            />
          </Field>
          <Field
            description="The user list type affects which identifiers you could upload to Google Ads."
            label="What type is the user list?"
            size="large"
          >
            <RadioGroup
              options={[
                {
                  label: "CONTACT_INFO",
                  value: "CONTACT_INFO",
                  description: "For email, phone number or physical address identifiers",
                },
                {
                  label: "MOBILE_ADVERTISING_ID",
                  value: "MOBILE_ADVERTISING_ID",
                  description: "For mobile identifiers (ID/IDFA)",
                },
                { label: "CRM_ID", value: "CRM_ID", description: "For Advertiser-assigned user ID" },
              ]}
              value={config?.userListType as string}
              onChange={(userListType) => {
                setConfig({ ...config, userListType });
              }}
            />
          </Field>
          {config.userListType === "MOBILE_ADVERTISING_ID" && (
            <Field
              description="App ID is a string that uniquely identifies a mobile application from which the data was collected. For iOS, the ID string is the 9 digit string that appears at the end of an App Store URL (e.g., '476943146' for 'Flood-It! 2' whose App Store link is http://itunes.apple.com/us/app/flood-it!-2/id476943146). For Android, the ID string is the application's package name (e.g., 'com.labpixies.colordrips' for 'Color Drips' given Google Play link https://play.google.com/store/apps/details?id=com.labpixies.colordrips)."
              label="What is the app ID of the audience?"
              size="large"
            >
              <Input
                defaultValue={config?.userListAppId as string}
                placeholder="App ID"
                sx={{ width: "240px" }}
                onChange={(userListAppId) => setConfig({ ...config, userListAppId })}
              />
            </Field>
          )}
          <Field
            optional
            description="Number of days a user's cookie stays on your list since its most recent addition to the list. This field must be between 0 and 540 inclusive."
            error={errors?.userListLifeSpan}
            label="What is the membership life span for the list?"
            size="large"
          >
            <Input
              disabled={config?.userListDontExpireLifeSpan as boolean}
              max="540"
              min="0"
              placeholder="30"
              sx={{ width: "180px" }}
              type="number"
              value={(config?.userListLifeSpan as string) || ""}
              onChange={(userListLifeSpan) => {
                const val = userListLifeSpan ? parseInt(userListLifeSpan) : undefined;
                setConfig({ ...config, userListLifeSpan: val });
              }}
            />

            <Checkbox
              label="Do not expire"
              sx={{ mt: 2 }}
              value={config?.userListDontExpireLifeSpan as boolean}
              onChange={(userListDontExpireLifeSpan) => {
                setConfig({ ...config, userListDontExpireLifeSpan, userListLifeSpan: undefined });
              }}
            />
          </Field>
        </Grid>
      </Section>
      <Section>
        <MappingsField options={columnOptions} />
      </Section>
      <PIIHashingField />
      <Section>
        <Grid gap={8}>
          <Field
            description={
              "If enabled, Hightouch will try to convert values \
              mapped to phone data into E164 format. If the phone \
              number cannot be converted, it will not be included in \
              the request."
            }
            label="Would you like Hightouch to clean your phone numbers?"
            size="large"
          >
            <RadioGroup
              options={[
                { label: "Yes, clean phone numbers for me", value: true },
                { label: "No, I will clean them myself", value: undefined },
              ]}
              value={config?.cleanPhone as boolean}
              onChange={(cleanPhone) => {
                setConfig({ ...config, cleanPhone });
              }}
            />
          </Field>
          {config?.cleanPhone ? (
            <Field
              description={"Mapping a phone country column in the \
                field mappings will overwrite this value."}
              label="What is the default country for the phone numbers (ISO code)?"
            >
              <Input
                defaultValue={config?.defaultCountry as string}
                error={Boolean(errors?.defaultCountry)}
                placeholder="US"
                sx={{ width: "240px" }}
                onChange={(defaultCountry) => {
                  setConfig({
                    ...config,
                    defaultCountry,
                  });
                }}
              />
            </Field>
          ) : undefined}
        </Grid>
      </Section>
    </>
  );
}

const Account = ({ account, rootId, accountId, setAccountIds, loginAccountId, isRoot = false }) => {
  const children: ReactElement[] = [];
  if (account?.child) {
    for (const childAccount of Object.values(account?.child) || []) {
      children.push(
        <Account
          account={childAccount}
          accountId={accountId}
          loginAccountId={loginAccountId}
          rootId={rootId}
          setAccountIds={setAccountIds}
        />,
      );
    }
  }

  if (account.disabled) {
    // You can not longer select a disabled account.
    return (
      <Box>
        <Text>{`${account?.name} (${account?.id})`}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Checkbox
        label={`${account?.name} (${account?.id})`}
        value={
          accountId?.replace(/[ -]/g, "") === account?.id &&
          (loginAccountId?.replace(/[ -]/g, "") === rootId || (!loginAccountId && isRoot))
        }
        onChange={() => {
          if (isRoot) {
            setAccountIds(account?.id, undefined);
          } else {
            setAccountIds(account?.id, rootId);
          }
        }}
      />
      {!!children?.length && (
        <Box sx={{ ml: 2, pl: 2, mt: 2, borderLeft: "medium", borderColor: "blacks.0" }}>
          <Grid gap={1}>{children}</Grid>
        </Box>
      )}
    </Box>
  );
};

export const Schema = Yup.object().shape(
  {
    accountId: Yup.string().required(),
    loginAccountId: Yup.string().nullable().notRequired(),
    mode: Yup.string().required().default("CustomerMatchUserList"),
    mappings: COMMON_SCHEMAS.mappings
      .required()
      .test(
        "atLeastOneIdentifier",
        "Mappings must contain at least one user identifier (contact info or physical address info)",
        function () {
          const mappings: { to: string }[] = this.parent.mappings;
          return mappings.some((mapping) => USER_IDENTIFIERS.includes(mapping.to));
        },
      ),
    userListName: Yup.string().notRequired(),
    userListType: Yup.string().oneOf(["CONTACT_INFO", "CRM_ID", "MOBILE_ADVERTISING_ID"]).required().default("CONTACT_INFO"),
    userListAppId: Yup.string().when("userListType", {
      is: (value) => value === "MOBILE_ADVERTISING_ID",
      then: Yup.string().required(),
    }),
    userListLifeSpan: Yup.number().min(0).max(540).notRequired(),
    userListDontExpireLifeSpan: Yup.boolean().notRequired(),
    cleanPhone: Yup.boolean().notRequired(),
    defaultCountry: Yup.string().when("cleanPhone", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    disablePIIHashing: Yup.boolean().notRequired(),
  },
  [["cleanPhone", "defaultCountry"]],
);
