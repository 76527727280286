import { FC } from "react";

import { useToasts } from "react-toast-notifications2";
import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useSlackDestinationChannelsQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { TextArea, Input } from "src/ui/input";
import { Label } from "src/ui/label";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { ColumnOrConstantField } from "../column-or-constant-field";
import { ModeField } from "../mode-field";

export const validation = Yup.object().shape({
  channel: COMMON_SCHEMAS.columnOrConstant,
  format: Yup.string().required().default("message"),
  isBlockKit: Yup.boolean().notRequired(),

  channelColumn: Yup.string().notRequired(),
  addedBody: Yup.string().when("format", {
    is: "message",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  changedBody: Yup.string().notRequired(),
  removedBody: Yup.string().notRequired(),

  rowsPerMessage: Yup.number().integer().positive().notRequired(),
  header: Yup.string().notRequired(),
  body: Yup.string().when("format", {
    is: "batchMessage",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  footer: Yup.string().notRequired(),

  iconUrl: Yup.string().notRequired(),
  iconEmoji: Yup.string().notRequired(),
  username: Yup.string().notRequired(),
});

const FORMATS = [
  { label: "Message", value: "message" },
  { label: "Batch Message", value: "batchMessage" },
  { label: "Table", value: "table" },
  { label: "CSV", value: "csv" },
];

export const SlackForm: FC = () => {
  const { config, setConfig, errors, destination } = useDestinationForm();

  const { addToast } = useToasts();

  const {
    data: channelsData,
    error: channelsError,
    isFetching: loadingChannels,
    refetch: getChannels,
  } = useSlackDestinationChannelsQuery({
    destinationId: String(destination?.id),
  });

  const channels = channelsData?.slackListChannels?.channels;

  const beautifyJSONFields = () => {
    if (!config?.isBlockKit) return;
    const newFields = {};
    for (const field of ["addedBody", "changedBody", "removedBody", "header", "footer", "body"]) {
      if (config[field]) {
        const beautified = beautifyJSON(config[field], () => {
          addToast(`The field ${field} is not a valid JSON.`, {
            appearance: "warning",
          });
        });
        newFields[field] = beautified;
      }
    }
    setConfig({ ...config, ...newFields });
  };

  const previewBlockKit = () => {
    if (!config?.isBlockKit) return;
    let blocks = [];
    if (config?.format === "message") {
      let addJSON = [];
      let removeJSON = [];
      let changeJSON = [];

      try {
        addJSON = config?.addedBody ? JSON.parse(config?.addedBody) : [];
        changeJSON = config?.removedBody ? JSON.parse(config?.changedBody) : [];
        removeJSON = config?.removedBody ? JSON.parse(config?.removedBody) : [];
      } catch (e) {
        addToast(`The fields addedBody, changedBody, and removedBody are not valid JSON.`, {
          appearance: "warning",
        });
        return;
      }
      if (!(addJSON instanceof Array && removeJSON instanceof Array)) {
        addToast(`The fields addedBody, changedBody, and removedBody must be an array.`, {
          appearance: "warning",
        });
        return;
      }
      blocks = [...addJSON, ...changeJSON, ...removeJSON];
    }
    if (config?.format === "batchMessage") {
      let headerJSON = [];
      let bodyJSON = [];
      let footerJSON = [];

      try {
        headerJSON = config?.header ? JSON.parse(config?.header) : [];
        bodyJSON = config?.body ? JSON.parse(config?.body) : [];
        footerJSON = config?.footer ? JSON.parse(config?.footer) : [];
      } catch (e) {
        addToast(`The fields header, body, and footer are not valid JSON.`, {
          appearance: "warning",
        });
        return;
      }
      if (!(headerJSON instanceof Array && bodyJSON instanceof Array && footerJSON instanceof Array)) {
        addToast(`The fields header, body, and footer must be an array.`, {
          appearance: "warning",
        });
        return;
      }

      blocks = [...headerJSON, ...bodyJSON, ...footerJSON];
    }
    const blockString = JSON.stringify({ blocks });
    const urlString = `https://app.slack.com/block-kit-builder#${encodeURIComponent(blockString)}`;
    window.open(urlString, "_blank");
  };

  const channelOptions =
    channels?.map((c) => ({
      label: c.name,
      value: c.id,
    })) || [];

  return (
    <>
      <ModeField
        options={FORMATS}
        value={config?.format}
        onChange={(format) => {
          setConfig({
            format,
          });
        }}
      />

      <Section>
        <ColumnOrConstantField
          columnDescription="Ensure that the Hightouch bot is invited to the channels listed in the column. The column can also contain Slack User IDs or emails of Slack users, with which Hightouch will send a direct message."
          columnLabel="Which column contains the Slack channels you would like to send updates to?"
          constantDescription={
            "If you don't see your Slack channel here, make sure to invite the Hightouch bot to the channel."
          }
          constantInput={
            <Select
              isError={Boolean(errors?.channel)}
              isLoading={loadingChannels}
              options={channelOptions}
              placeholder="Select a channel..."
              reload={getChannels}
              sx={{ maxWidth: "280px" }}
              value={config?.channel ? channelOptions?.find((s) => config?.channel === s.value) : null}
              width="280px"
              onChange={(selected) => {
                setConfig({
                  ...config,
                  channel: selected?.value,
                });
              }}
            />
          }
          constantLabel="Which Slack channel would you like to send updates to?"
          disabled={config?.format !== "message"}
          error={channelsError?.message}
          property="channel"
        />
      </Section>

      {config?.format && (config?.format === "message" || config?.format === "batchMessage") && (
        <Section>
          <Field error={errors?.isBlockKit} label="Is the content for the message plaintext or Slack block kit?" size="large">
            <Grid gap={2}>
              <RadioGroup
                options={[
                  {
                    label: "Plaintext",
                    value: undefined,
                  },
                  {
                    label: "Slack Block Kit",
                    value: true,
                  },
                ]}
                value={config?.isBlockKit}
                onChange={(isBlockKit) => {
                  setConfig({ ...config, isBlockKit });
                }}
              />
            </Grid>
          </Field>
        </Section>
      )}

      {config?.format && config?.format === "message" && (
        <>
          <Section>
            <Field
              description={
                config?.isBlockKit ? "Ensure that the content is an array of blocks and is a valid JSON string." : null
              }
              error={errors?.addedBody}
              label="What content would you like to send when a row is added?"
              size="large"
            >
              <TextArea
                error={errors?.addedBody}
                placeholder={
                  config?.isBlockKit
                    ? `Ex: [{"type": "section", "text": {"type": "plain_text", "text": "User with name {{ name }} and email {{ email }} has signed up"}}]`
                    : "Ex: User with name {{ name }} and email {{ email }} has signed up"
                }
                rows={10}
                value={config?.addedBody || ""}
                onValue={(addedBody) => setConfig({ ...config, addedBody })}
              />
            </Field>
          </Section>
          <Section>
            <Field
              optional
              description={
                config?.isBlockKit ? "Ensure that the content is an array of blocks and is a valid JSON string." : null
              }
              error={errors?.changedBody}
              label="What content would you like to send when a row is changed?"
              size="large"
            >
              <TextArea
                error={errors?.changedBody}
                placeholder={
                  config?.isBlockKit
                    ? `Ex: [{"type": "section", "text": {"type": "plain_text", "text": "User with name {{ name }} and email {{ email }} has updated their profile."}}]`
                    : "Ex: User with name {{ name }} and email {{ email }} has updated their profile."
                }
                rows={10}
                value={config?.changedBody || ""}
                onValue={(changedBody) => setConfig({ ...config, changedBody })}
              />
            </Field>
          </Section>
          <Section>
            <Field
              optional
              description={
                config?.isBlockKit ? "Ensure that the content is an array of blocks and is a valid JSON string." : null
              }
              error={errors?.removedBody}
              label="What content would you like to send when a row is removed?"
              size="large"
            >
              <TextArea
                error={errors?.removedBody}
                placeholder={
                  config?.isBlockKit
                    ? `Ex: [{"type": "section", "text": {"type": "plain_text", "text": "User with name {{ name }} and email {{ email }} has been deleted"}}]`
                    : "Ex: User with name {{ name }} and email {{ email }} has been deleted"
                }
                rows={10}
                value={config?.removedBody || ""}
                onValue={(removedBody) => setConfig({ ...config, removedBody })}
              />
            </Field>
            {config?.isBlockKit && (
              <Grid sx={{ gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center", mt: 6 }}>
                <Button label="Beautify JSON" variant="secondary" onClick={beautifyJSONFields} />
                <Button label="Preview Block Kit" variant="secondary" onClick={previewBlockKit} />
              </Grid>
            )}
          </Section>
        </>
      )}

      {config?.format && config?.format === "batchMessage" && (
        <Section>
          <Label size="large">Would you like to customize the content?</Label>
          <Grid gap={8}>
            <Field
              optional
              description={
                config?.isBlockKit ? "Ensure that the content is an array of blocks and is a valid JSON string." : null
              }
              error={errors?.header}
              label="Message header?"
            >
              <TextArea
                error={errors?.header}
                placeholder={
                  config?.isBlockKit
                    ? `Ex: [{"type": "section", "text": {"type": "plain_text", "text": "The following users have signed up in the last week:"}}]`
                    : "Ex: The following users have signed up in the last week:"
                }
                rows={10}
                value={config?.header || ""}
                onValue={(header) => setConfig({ ...config, header })}
              />
            </Field>
            <Field
              description={
                config?.isBlockKit ? "Ensure that the content is an array of blocks and is a valid JSON string." : null
              }
              error={errors?.body}
              label="Row content"
            >
              <TextArea
                error={errors?.body}
                placeholder={
                  config?.isBlockKit
                    ? `Ex: [{"type": "section", "text": {"type": "plain_text", "text": "- {{ name }}, {{ email }}"}}]`
                    : "Ex: - {{ name }}, {{ email }}"
                }
                rows={10}
                value={config?.body || ""}
                onValue={(body) => setConfig({ ...config, body })}
              />
            </Field>
            <Field
              optional
              description={
                config?.isBlockKit ? "Ensure that the content is an array of blocks and is a valid JSON string." : null
              }
              error={errors?.footer}
              label="Message footer?"
            >
              <TextArea
                error={errors?.footer}
                placeholder={
                  config?.isBlockKit
                    ? `Ex: [{"type": "section", "text": {"type": "plain_text", "text": "Please make them happy!"}}]`
                    : "Ex: Please make them happy!"
                }
                rows={10}
                value={config?.footer || ""}
                onValue={(footer) => setConfig({ ...config, footer })}
              />
            </Field>
            {config?.isBlockKit && (
              <Grid sx={{ gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center", mt: 6 }}>
                <Button label="Beautify JSON" variant="secondary" onClick={beautifyJSONFields} />
                <Button label="Preview Block Kit" variant="secondary" onClick={previewBlockKit} />
              </Grid>
            )}
          </Grid>
        </Section>
      )}

      {config?.format && (config?.format === "message" || config?.format === "batchMessage" || config?.format === "table") && (
        <Section>
          <Label size="large">Would you like to customize the Hightouch bot?</Label>
          <Grid gap={8}>
            <Field optional error={errors?.username} label="Display name">
              <Input
                error={errors?.username}
                placeholder="Hightouch"
                sx={{ width: "240px" }}
                value={config?.username || ""}
                onChange={(value) =>
                  setConfig({
                    ...config,
                    username: value,
                  })
                }
              />
            </Field>
            <Field optional error={errors?.iconUrl} label="Icon">
              <Input
                error={errors?.iconUrl}
                placeholder="https://picsum.photos/48/48"
                sx={{ width: "240px" }}
                value={config?.iconUrl || ""}
                onChange={(value) =>
                  setConfig({
                    ...config,
                    iconUrl: value,
                  })
                }
              />
            </Field>

            <Field optional description="This will override icon_url." error={errors?.iconEmoji} label="Emoji">
              <Input
                error={errors?.iconEmoji}
                placeholder=":chart_with_upwards_trend:"
                sx={{ width: "240px" }}
                value={config?.iconEmoji || ""}
                onChange={(value) =>
                  setConfig({
                    ...config,
                    iconEmoji: value,
                  })
                }
              />
            </Field>
            {config?.format === "batchMessage" && (
              <Field
                optional
                description="Defaults to 10 rows per message"
                label="How many rows would you like to send per message?"
              >
                <Input
                  defaultValue={config?.rowsPerMessage}
                  max="20"
                  min="1"
                  placeholder="10"
                  sx={{ width: "180px" }}
                  type="number"
                  onChange={(rowsPerMessage) =>
                    setConfig({ ...config, rowsPerMessage: rowsPerMessage ? Number(rowsPerMessage) : undefined })
                  }
                />
              </Field>
            )}
          </Grid>
        </Section>
      )}
      {(config?.format === "table" || config?.format === "csv") && (
        <Section>
          <Field error={errors?.sendEmpty} label="How should Slack handle empty result messages?" size="large">
            <RadioGroup
              options={[
                { label: "Don't send", description: "Don't send message when results are empty", value: undefined },
                {
                  label: "Send message",
                  description: "Message will be sent even when results are empty",
                  value: true,
                },
              ]}
              value={config?.sendEmpty}
              onChange={(sendEmpty) => {
                setConfig({ ...config, sendEmpty });
              }}
            />
          </Field>
        </Section>
      )}
    </>
  );
};

const beautifyJSON = (body, onError: null | (() => void) = null) => {
  let obj;
  try {
    obj = JSON.parse(body);
  } catch (err) {
    if (onError) {
      onError();
    }
    return body;
  }
  return JSON.stringify(obj, null, 4);
};

export default {
  form: SlackForm,
  validation,
};
