import { ReactElement } from "react";

import { Row } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { Box, Text, Grid } from "theme-ui";

import { ReloadButton } from "src/ui/button";
import { Spinner } from "src/ui/loading";
import { RadioGroup } from "src/ui/radio";

type Options = {
  label: string;
  value: string;
  childrenOptions?: Options[];
  description?: string;
  disabled?: boolean;
};

export type Props = {
  options: Options[];
  value: string[];
  /**
   * A prop that determines whether the parent options of the child option will be shown
   * as selected. This does not affect the logic and is for visual purposes only.
   */
  allNestedSelected: boolean;
  loading: any;
  reload: () => void;
  onChange: (value: string[]) => void;
};

export type ChildOptionProps = Pick<Props, "onChange" | "value" | "allNestedSelected"> & {
  option: Options;
  parentOptions: string[];
  depthTracker: number;
};

export const NestedRadioGroup = ({ options, value, allNestedSelected, loading, reload, onChange }: Props) => {
  if (loading) {
    return <Spinner size={64} />;
  }

  return (
    <Row>
      <Box sx={{ maxHeight: "400px", border: "small", overflow: "auto", p: 3, borderRadius: 4, flex: 1 }}>
        <Grid gap={2}>
          {Array.isArray(options) &&
            options?.map((option, i) => {
              return (
                <ChildOption
                  key={i}
                  allNestedSelected={allNestedSelected}
                  depthTracker={0}
                  option={option}
                  parentOptions={[option.value]}
                  value={value}
                  onChange={onChange}
                />
              );
            })}
        </Grid>
      </Box>
      {reload && <ReloadButton loading={loading} sx={{ ml: 2 }} onClick={reload} />}
    </Row>
  );
};

const ChildOption = ({
  option,
  value = [],
  parentOptions,
  depthTracker,
  allNestedSelected = false,
  onChange,
}: ChildOptionProps) => {
  const children: ReactElement[] = [];
  if (option?.childrenOptions) {
    for (const childAccount of Object.values(option?.childrenOptions) || []) {
      children.push(
        <ChildOption
          allNestedSelected={allNestedSelected}
          depthTracker={depthTracker + 1}
          option={childAccount}
          parentOptions={[...parentOptions, childAccount.value]}
          value={value}
          onChange={onChange}
        />,
      );
    }
  }

  if (option.disabled) {
    // You can no longer select a disabled account.
    return (
      <Box>
        <Text>{`${option?.label} (${option?.value})`}</Text>
      </Box>
    );
  }

  /**
   * We use this comparison to determine whether RadioGroup's prop 'value' should be false (unselected) or
   * the selected option. This is done at every nested layer to achieve the visual result that every parent option
   * of the selected child option is also seen as selected.
   *
   * For example, selecting option A1 in the following nested radiogroup will achieve the result:
   * (x) - A0
   *  (x) - A1
   * ( ) - B
   * ( ) - C
   **/
  const hasSameParent = isEqual(parentOptions, value.slice(0, depthTracker + 1));

  return (
    <Box>
      <RadioGroup
        options={[{ label: option.label, value: option.value }]}
        value={allNestedSelected ? hasSameParent && value[depthTracker] : value[value.length - 1]}
        onChange={() => {
          onChange(parentOptions);
        }}
      />
      {!!children?.length && (
        <Box sx={{ ml: 4, pl: 1, mt: 1, borderLeft: "medium", borderColor: "blacks.0" }}>
          <Grid gap={1}>{children}</Grid>
        </Box>
      )}
    </Box>
  );
};
